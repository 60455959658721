import React from 'react';
import { ROUTES } from '../../common/constants/routes';
import { SignIn, UserButton } from '@clerk/clerk-react';

const ClerkLogIn = () => {
  return (
    <React.Fragment>
      <div>
        <SignIn signUpUrl={ROUTES.SIGN_UP} />
      </div>
    </React.Fragment>
  );
};

export default ClerkLogIn;
