import React, { useEffect, useState } from 'react';
import ListItemBlock from '../../common/list-item-block/ListItemBlock';
import { ROUTES } from '../../common/constants/routes';
import { Box, Typography, List, ListItem, Button, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TaskItem from '../../common/task-item-block/task-item-block';
import ListItemButton from '../../common/list-item-button/ListItemButton';
import { ButtonGroup } from 'react-bootstrap';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { deleteTodo, fetchTodos, updateTodo } from '../../slices/todo/todoSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import AddTodoForm from '../categories/components/TodoForm/TodoForm';
import { ToDoStatus } from '../../common/task-item-block/types';
import { ToDo } from '../../models/todo.model';

const DemoHome: React.FC = () => {
  const { t } = useTranslation();
  const recentDocumentsArray = [
    { id: 1, name: 'Casual contract agreement', labelText: 'Vault', isFavourite: true },
    { id: 2, name: 'New employee contract', labelText: 'Recently used', isFavourite: false },
    { id: 3, name: 'Privacy agreement and NDA', labelText: 'Recently used', isFavourite: false },
    { id: 4, name: 'Termination', labelText: 'Recent used', isFavourite: false },
  ];
  const recentProfilesArray: any[] = [];

  const profileButtonsArray = [
    { id: 1, buttonText: 'Employee', route: ROUTES.EMPLOYEE_PROFILES },
    { id: 2, buttonText: 'Customer', route: ROUTES.CUSTOMER_PROFILES },
    { id: 3, buttonText: 'Supplier', route: ROUTES.SUPPLIER_PROFILES },
  ];

  const dispatch = useAppDispatch();
  const todos = useAppSelector((state) => state.todos.items);
  const [isSkipped, setIsSkipped] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedTodo, setSelectedTodo] = useState(null);

  useEffect(() => {
    if (isSkipped) {
      dispatch(fetchTodos());
    }
  }, [isSkipped, dispatch]);

  const handleSkip = () => {
    setIsSkipped(true);
  };
  const handleAction = (action: string, todo: any) => {
    if (action === 'delete') {
      dispatch(deleteTodo({ id: todo.id }));
    }
    if (action === 'modify') {
      setSelectedTodo(todo);
      setOpen(true);
    }
  };
  const handleStart = (todoId: number) => {
    const todo = todos.find((t) => t.id === todoId);
    if (todo) {
      dispatch(
        updateTodo({
          id: todoId,
          data: {
            ...todo,
            started_at: new Date().toISOString(),
          },
        })
      );
    }
  };

  const handleComplete = (todoId: number) => {
    const todo = todos.find((t) => t.id === todoId);
    if (todo) {
      dispatch(
        updateTodo({
          id: todoId,
          data: {
            ...todo,
            completed_at: new Date().toISOString(),
          },
        })
      );
    }
  };
  const getTodoStatus = (todo: ToDo): ToDoStatus => {
    if (todo.completed_at) {
      return 'completed';
    }
    const now = new Date();
    const dueDate = new Date(todo.due_at);
    if (now > dueDate) {
      return 'overdue';
    }
    if (todo.started_at) {
      return 'in_progress';
    }
    return 'unstarted';
  };
  const sortTodos = (todos: ToDo[]): ToDo[] => {
    const groups: { [key in ToDoStatus]: ToDo[] } = {
      overdue: [],
      in_progress: [],
      unstarted: [],
      completed: [],
    };

    todos.forEach((todo) => {
      const status = getTodoStatus(todo);
      groups[status].push(todo);
    });
    groups.overdue.sort((a, b) => new Date(b.due_at).getTime() - new Date(a.due_at).getTime());
    groups.in_progress.sort((a, b) => new Date(a.due_at).getTime() - new Date(b.due_at).getTime());
    groups.unstarted.sort((a, b) => new Date(a.due_at).getTime() - new Date(b.due_at).getTime());
    return [...groups.overdue, ...groups.in_progress, ...groups.unstarted, ...groups.completed];
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className='d-md-flex home-page-wrapper mt-4 mb-4'>
      <div className='flex-md-grow-1 d-md-flex flex-md-column'>
        <div className='d-md-flex flex-md-column'>
          <div className='flex-md-grow-1'>
            <Box className='box-with-header flex-grow-1'>
              <Box className='header-action-row d-flex justify-content-between'>
                <Typography variant='h2'>{t('headers.documents')}</Typography>
              </Box>
              <Box className='d-flex align-items-start flex-wrap gap-2 buttons-container'>
                <ListItemButton buttonText={t('buttons.store')} />
                <ListItemButton buttonText={t('buttons.sign')} />
              </Box>
            </Box>
          </div>
          <div className='flex-md-grow-1'>
            <Box className='box-with-header flex-grow-1'>
              <Box className='header-action-row d-flex justify-content-between'>
                <Typography variant='h2'>{t('headers.profiles')}</Typography>
              </Box>
              <Box className='d-flex align-items-start flex-wrap gap-2 buttons-container'>
                <ListItemButton buttonText={t('buttons.employee')} />
                <ListItemButton buttonText={t('buttons.supplier')} />
                <ListItemButton buttonText={t('buttons.customer')} />
              </Box>
            </Box>
          </div>
        </div>
        <div className='d-flex flex-grow-1 flex-column'>
          <Box className='box-with-header flex-grow-1'>
            <Box className='header-action-row d-flex justify-content-between'>
              <Typography variant='h2'>{t('headers.toDo')}</Typography>
              {/*
              <Link to={null}>
                {!isSkipped && (
                  <Button variant='contained' onClick={handleSkip}>
                    Skip
                  </Button>
                )}
                {isSkipped && (
                  <AddIcon
                    className='add-icon'
                    style={{ cursor: 'pointer' }}
                    onClick={handleOpen}
                  />
                )}
              </Link>*/}
            </Box>
            {/*!isSkipped && (
              <List className='tasks-list pt-0 pb-0'>
                <ListItem>
                  <TaskItem taskText={'Upload and store a document'} />
                </ListItem>
                <ListItem>
                  <TaskItem taskText={'Create new document'} taskCompleted />
                </ListItem>
                <ListItem>
                  <TaskItem taskText={'Add employee'} />
                </ListItem>
                <ListItem>
                  <TaskItem taskText={'Create a template from  '} taskCompleted />
                </ListItem>
                <ListItem>
                  <TaskItem taskText={'Create new document'} taskCompleted />
                </ListItem>
                <ListItem>
                  <TaskItem taskText={'Add a supplier or customer'} />
                </ListItem>
                <ListItem>
                  <TaskItem taskText={'Document summary with AI'} taskCompleted />
                </ListItem>
                <ListItem>
                  <TaskItem taskText={'Start subscription'} />
                </ListItem>
              </List>
            )}
            {isSkipped && (
              <List className='tasks-list pt-0 pb-0'>
                {sortTodos(todos).map((todo) => (
                  <ListItem key={todo.id}>
                    <TaskItem
                      taskText={todo.message}
                      date={new Date(todo.due_at)}
                      status={getTodoStatus(todo)}
                      iconLeft
                      onAction={(action) => handleAction(action, todo)}
                      actions
                      onStart={() => handleStart(todo.id)}
                      onComplete={() => handleComplete(todo.id)}
                    />
                  </ListItem>
                ))}
              </List>
            )*/}
          </Box>
        </div>
      </div>
      <div className='flex-md-shrink-0 d-md-flex flex-md-column'>
        <div>
          <ListItemBlock
            headerText={'Recent documents'}
            listArray={recentDocumentsArray}
            withLinkButton
            linkURL={ROUTES.DOCUMENT_VAULT}
            emptyStateMessage={t('emptyStateMessages.documents')}
          />
        </div>
        <div className='flex-grow-1'>
          <ListItemBlock
            headerText={'Recent profiles'}
            listArray={recentProfilesArray}
            emptyStateMessage={t('emptyStateMessages.profiles')}
          />
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            p: 4,
            backgroundColor: 'white',
            borderRadius: '8px',
            margin: 'auto',
            mt: '10%',
            width: 400,
          }}
        >
          <AddTodoForm existingTodo={selectedTodo} />
        </Box>
      </Modal>
    </div>
  );
};

export default DemoHome;
