import React from 'react';
import StartPageTemplate from '../../common/start-page-template/StartPageTemplate';
import SignUpForm from './SignUpForm';
import Button from '@mui/material/Button';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { useTranslation } from 'react-i18next';
import FeaturesContentLogIn from '../login/FeaturesContentLogIn';
import ClerkSignUp from './ClerkSignUp';

const SignUp = () => {
  const location = useLocation();
  const invitationURL = location.pathname.includes('accept_invite');
  const { t } = useTranslation();
  const FormContent = () => {
    return (
      <div className='user-form-container'>
        <h1>{invitationURL ? t('signUpText.headerInvitation') : t('signUpText.header')}</h1>
        {invitationURL ? null : <p className='text-center'>{t('signUpText.subheader')}</p>}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ClerkSignUp />
        </div>
      </div>
    );
  };
  const BrandContent = () => {
    return (
      <div className='features-container'>
        <FeaturesContentLogIn />
      </div>
    );
  };
  return (
    <StartPageTemplate
      brandContainerContent={<BrandContent />}
      secondaryContainerContent={<FormContent />}
    />
  );
};

export default SignUp;
