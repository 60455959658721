import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { Company } from '../../models/company.model';
import { RootState } from '../../store';

export const fetchCompanies = createAsyncThunk('companies/fetchCompanies', async () => {
  const response = await NWClient.list('company');
  return response;
});

export interface CompaniesState {
  companiesList: Company[];
  status: string;
  error: string;
}

const companiesArray: Company[] = [];
const initialState: CompaniesState = { companiesList: companiesArray, status: 'idle', error: '' };
const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.companiesList = [...action.payload];
          state.status = 'succeeded';
        }
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default companiesSlice.reducer;

export const selectAllCompanies = (state: RootState) => state.companies.companiesList;
export const selectCompanyById = (state: RootState, companyId: number) =>
  state.companies.companiesList.find((item) => item.id === companyId);
