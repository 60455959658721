import React from 'react';
import { ROUTES } from '../../common/constants/routes';
import { SignedOut, SignUp } from '@clerk/clerk-react';

const SignedOutAny = SignedOut as React.FC<{ children: React.ReactNode }>;

const ClerkSignUp = () => {
  return (
    <React.Fragment>
      <div>
        <SignedOutAny>
          <SignUp signInUrl={ROUTES.LOGIN} />
        </SignedOutAny>
      </div>
    </React.Fragment>
  );
};

export default ClerkSignUp;
