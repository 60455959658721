import React from 'react';
import { List, ListItem, Typography, Divider, Box } from '@mui/material';
import { ListItemBlockProps } from './types';
import { Link } from 'react-router-dom';
import StarIcon from '../../../static/assets/svg/kid_star.svg';
import AddIcon from '../../../static/assets/svg/add-icon.svg';

const ListItemBlock = ({
  headerText,
  withLinkButton,
  listArray,
  onListItemClick,
  linkURL,
  emptyStateMessage,
}: ListItemBlockProps) => {
  return (
    <div className='list-item-block' style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
      {(headerText || withLinkButton) && (
        <Box
          className='list-header'
          sx={{
            justifyContent: headerText ? 'space-between' : 'flex-end',
          }}
        >
          {headerText && (
            <Typography variant='h2' className='header-text'>
              {headerText}
            </Typography>
          )}
          {withLinkButton && (
            <Link
              to={linkURL}
              className='add-icon-wrapper d-flex justify-content-center align-items-center'
            >
              <AddIcon />
            </Link>
          )}
        </Box>
      )}
      {listArray.length > 0 ? (
        <List
          className='list-content'
          sx={{
            padding: headerText || withLinkButton ? '0 16px 16px' : '16px',
          }}
        >
          {listArray.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem
                className='list-item'
                onClick={onListItemClick}
                sx={{
                  cursor: onListItemClick ? 'pointer' : 'initial',
                  '&:hover': {
                    bgcolor: '#F8F9FC',
                  },
                }}
              >
                {item.isFavourite && <StarIcon />}
                <Typography className='item-name'>{item.name}</Typography>
                <Box className='item-details'>
                  <Typography className='label-text'>{item.labelText}</Typography>
                </Box>
              </ListItem>
              <Divider className='item-divider' />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Box className='d-flex flex-column flex-grow-1 justify-content-center align-items-center empty-state-message-container '>
          <div className='empty-state-message'>{emptyStateMessage}</div>
        </Box>
      )}
    </div>
  );
};

export default ListItemBlock;
