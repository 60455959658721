import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import NWAppBar from '../../common/nw-app-bar/NWAppBar';
import NWSideNav from '../../common/nw-side-nav/NWSideNav';
import { ROUTES } from '../../common/constants/routes';
import MainNavigation from '../../common/main-navigation/MainNavigation';
import AppBar from '../../common/app-bar/AppBar';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useAuth } from '@clerk/clerk-react';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';

const Layout = (): JSX.Element => {
  const { documentId } = useParams();
  const [tokenLoaded, setTokenLoaded] = useState<boolean>(false);
  const designUpdated = useAppSelector((state) => state.designUpdated.designUpdated);
  const location = useLocation();

  const { getToken, isSignedIn, isLoaded } = useAuth();
  const getTokenValue = async () => {
    const token = await getToken();
    localStorage.setItem('access_token', `${token}`);
  };

  const locationWithFullWidth = [
    ROUTES.SIGNING,
    ROUTES.DOCUMENTS,
    ROUTES.DOCUMENT_VAULT_CATEGORY_DOCUMENT,
  ];
  const locationWithFullWidthUrl = locationWithFullWidth.some((el) => {
    return location.pathname.includes(el);
  });

  useEffect(() => {
    if (isSignedIn) {
      getTokenValue().then(() => {
        setTokenLoaded(true);
      });
    }
  }, [isSignedIn]);

  const renderedLayout = () => {
    return (
      <React.Fragment>
        <Box className={'layout' + (designUpdated ? ' updated-variant' : '')}>
          {designUpdated ? <MainNavigation /> : <NWSideNav />}
          <Box sx={{ width: '100%' }}>
            {designUpdated ? <AppBar /> : <NWAppBar />}
            <Container maxWidth={locationWithFullWidthUrl || documentId ? false : 'lg'}>
              <Outlet />
            </Container>
          </Box>
        </Box>
      </React.Fragment>
    );
  };

  if (!tokenLoaded) return <ProgressOverlay />;

  return renderedLayout();
};
export default Layout;
