import React, { ChangeEvent, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { NWClient } from '../../../../../../client/NWClient';
import DialogItem from '../../../../../../common/dialog-item/DialogItem';
import { Divider, Grid } from '@mui/material';
import EmploymentSettingsIcon from '../../../../../../../static/assets/svg/menu/profile-icon.svg';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector } from '../../../../../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../../../../../slices/companies/companiesSlice';
import { CompanyMember } from '../../../../../../models/member.model';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import { Dayjs } from 'dayjs';
import { Supplier } from '../../../../../../models/supplier.model';
import { Customer } from '../../../../../../models/customer.model';
import { selectUser } from '../../../../../../slices/user/userSlice';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Data = {
  user: number;
  document_library?: number;
  document_template?: number;
  category?: string;
  company_id: number;
  secondary_id?: number;
};
const ContractGeneratorDialog = (props: {
  open: boolean;
  handleClose: () => void;
  templateTitle: string;
  templateId: number;
  handleGenerateTemplate: (data?: Data) => void;
  templateLabels?: { categoryLabel: string; typeLabel: string; workflowLabel: string };
}) => {
  const { open, handleClose, templateTitle, handleGenerateTemplate, templateId, templateLabels } =
    props;
  const { t } = useTranslation();
  const { name } = useParams();
  const company = useAppSelector(selectAllCompanies)[0];
  const currentUser = useAppSelector(selectUser);
  const employees: CompanyMember[] = company?.members || [];
  const employeesFiltered = employees.filter((el) =>
    el.groups.every((item) => item.name !== 'Company admin')
  );
  const employeesSorted = employeesFiltered.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );
  const suppliers: Supplier[] = company?.suppliers || [];
  const suppliersSorted = suppliers.slice().sort((a, b) => a.name.localeCompare(b.name));
  const customers: Customer[] = company?.customers || [];
  const customersSorted = customers
    .slice()
    .sort((a, b) => a.customer_name.localeCompare(b.customer_name));
  const [companyData, setCompanyData] = useState<string>('');
  const [secondPartyCategory, setSecondPartyCategory] = useState<string>('employment');
  const [secondParty, setSecondParty] = useState<string>('noSecondParty');

  const [scroll, setScroll] = useState<'body' | 'paper'>('paper');
  const [openTerms, setOpenTerms] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(false);

  const resetDataOnClose = () => {
    setCompanyData('');
    setSecondParty('noSecondParty');
  };

  const getSecondParty = () => {
    switch (secondPartyCategory) {
      case 'employment':
        return employeesSorted;
      case 'customers':
        return customersSorted;
      case 'suppliers':
        return suppliersSorted;
      case 'business':
        return [];
    }
  };

  const getSecondPartyName = (option: {
    first_name?: string;
    last_name?: string;
    name?: string;
    customer_name?: string;
  }) => {
    switch (secondPartyCategory) {
      case 'employment':
        return option.first_name + ' ' + option.last_name;
      case 'customers':
        return option.customer_name;
      case 'suppliers':
        return option.name;
      case 'business':
        return '';
    }
  };

  const handleSubmitWizard = () => {
    if (!disclaimerAccepted) {
      setOpenTerms(true);
      return;
    }
    const data: Data = {
      [name === 'company-templates' ? 'document_template' : 'document_library']: templateId,
      user: currentUser?.id,
      company_id: company?.id,
      category: secondPartyCategory,
    };
    if (secondParty !== 'noSecondParty') {
      data.secondary_id = Number(secondParty);
    }
    handleGenerateTemplate(data);
  };

  const DisclaimerAcceptedWizard = () => {
    const data: Data = {
      [name === 'company-templates' ? 'document_template' : 'document_library']: templateId,
      user: currentUser?.id,
      company_id: company?.id,
      category: secondPartyCategory,
    };
    if (secondParty !== 'noSecondParty') {
      data.secondary_id = Number(secondParty);
    }
    handleGenerateTemplate(data);
  };

  const handleDisclaimerAccept = () => {
    setPendingStatus(true);
    NWClient.DisclaimerAccept()
      .then(() => {
        DisclaimerAcceptedWizard();
      })
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setPendingStatus(false);
        setOpenTerms(false);
        setDisclaimerAccepted(true);
      });
  };

  const handleTermsClose = () => {
    setOpenTerms(false);
  };
  useEffect(() => {
    NWClient.DisclaimerCheck()
      .then(() => {
        setDisclaimerAccepted(true);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  }, []);

  const disclaimerKeys = [
    'beforeProceeding',
    'description',
    'tosDocAccess',
    'resourceOffering',
    'documentAccessibility',
    'docAccuracy',
    'disclaimers',
    'legalities',
    'redistribution',
    'intellectualProperty',
    'contactInfo',
  ];

  return (
    <>
      <DialogItem
        open={open}
        handleClose={() => {
          resetDataOnClose();
          handleClose();
        }}
        closeIcon={true}
      >
        <Grid>
          <Grid container item className='setting-panel-header pt-2 pb-2 ps-0 pe-0'>
            <h4>{t('contractGeneratorDialog.generatorTitle')}</h4>
            <Tooltip title={t('messages.contractGeneratorGeneralMessage')} placement='top'>
              <InfoIcon fontSize='small' color='secondary' />
            </Tooltip>
          </Grid>
          <Grid container item className='setting-panel-header pt-2 pb-2 ps-0 pe-0'>
            <h5 className='fw-normal'>{templateTitle}</h5>
          </Grid>
          {templateLabels && (
            <Grid container item className='pt-1 pb-1 d-flex flex-wrap align-items-start'>
              {templateLabels && templateLabels.categoryLabel
                ? templateLabels.categoryLabel + ' / '
                : null}
              {templateLabels && templateLabels.workflowLabel
                ? templateLabels.workflowLabel + ' / '
                : null}
              {templateLabels && templateLabels.typeLabel ? templateLabels.typeLabel : null}
            </Grid>
          )}
          <Divider />
          <div className='setting-panel-body ps-0 pe-0'>
            <ValidatorForm onSubmit={() => handleSubmitWizard()}>
              <Grid container className='form-controls' spacing={4}>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    label={t('labels.businessDetails')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setCompanyData(e.target.value)}
                    disabled={true}
                    name='companyData'
                    value={company.name}
                    tabIndex={-1}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    select
                    label={t('labels.chooseProfile')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSecondPartyCategory(e.target.value);
                      setSecondParty('noSecondParty');
                    }}
                    name='secondPartyCategory'
                    value={secondPartyCategory}
                  >
                    <MenuItem value={'employment'}>{t('employees.header')}</MenuItem>
                    <MenuItem value={'customers'}>{t('Customers')}</MenuItem>
                    <MenuItem value={'suppliers'}>{t('documentVault.suppliers')}</MenuItem>
                    <MenuItem value={'business'}>{t('documentVault.business')}</MenuItem>
                  </TextValidator>
                </Grid>
                <Grid className='position-relative' item xs={12} md={6}>
                  <Tooltip
                    title={t('messages.selectAUser')}
                    placement='top'
                    style={{ position: 'absolute', right: '0', top: '32px', zIndex: 3 }}
                  >
                    <InfoIcon fontSize='small' color='secondary' />
                  </Tooltip>
                  <TextValidator
                    select
                    label={t('labels.chooseSecondParty')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSecondParty(e.target.value);
                    }}
                    name='secondParty'
                    value={secondParty}
                  >
                    <MenuItem key={'noSecondParty'} value={'noSecondParty'}>
                      {t('contractGeneratorDialog.noSecondParty')}
                    </MenuItem>
                    {getSecondParty().map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {getSecondPartyName(option)}
                      </MenuItem>
                    ))}
                  </TextValidator>
                </Grid>
                {/*<Grid item xs={12} md={6} className='datepicker-container'>
                  <InputLabel id='startDateLabel'>{t('labels.startDate')}</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        aria-labelledby='startDateLabel'
                        value={startDate}
                        format='DD.MM.YYYY'
                        onChange={(newValue) => setStartDate(newValue)}
                        slotProps={{
                          field: { clearable: true },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} className='datepicker-container'>
                  <InputLabel id='endDateLabel'>{t('labels.endDate')}</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        format='DD.MM.YYYY'
                        aria-labelledby='endDateLabel'
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        slotProps={{
                          field: { clearable: true },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>*/}
              </Grid>
              <Grid container className='buttons-row mt-4' justifyContent='flex-end'>
                <Button
                  type='submit'
                  role='button'
                  disabled={false}
                  variant='contained'
                  size='large'
                >
                  {t('buttons.next')}
                </Button>
                <Dialog
                  open={openTerms}
                  onClose={handleTermsClose}
                  scroll={scroll}
                  aria-labelledby='scroll-dialog-title'
                  aria-describedby='scroll-dialog-description'
                >
                  <DialogTitle id='scroll-dialog-title'>Document Terms</DialogTitle>
                  <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                      id='scroll-dialog-description'
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      <b>{t('disclaimerText.beforeProceeding')}</b>
                      {t('disclaimerText.description')}
                      <Link to='https://businesskitz.com.au/terms-and-conditions/' target='_blank'>
                        Terms and Conditions
                      </Link>
                      {' and Business Kitz '}
                      <Link to='https://businesskitz.com.au/privacy-policy/' target='_blank'>
                        Privacy Policy
                      </Link>
                      {'.\n\n' +
                        disclaimerKeys
                          .slice(2)
                          .map((key) => t(`disclaimerText.${key}`))
                          .join('') +
                        '\n\n'}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleTermsClose}>Go back</Button>
                    {!disclaimerAccepted && (
                      <Button
                        onClick={handleDisclaimerAccept}
                        variant='contained'
                        style={{ fontSize: '0.75rem', padding: '4px 16px', height: '30px' }}
                      >
                        Accept
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </Grid>
            </ValidatorForm>
          </div>
        </Grid>
      </DialogItem>
    </>
  );
};

export default ContractGeneratorDialog;
