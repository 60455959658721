import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DemoAuthFooter = () => {
  const { t } = useTranslation();

  const LINKS = {
    contact: 'https://businesskitz.com.au/contact-us/',
    privaceProlicy: 'https://businesskitz.com.au/business-kitz-privacy-policy/',
    termsAndConditions: 'https://businesskitz.com.au/business-kitz-platform-terms-and-conditions/',
  };
  return (
    <Grid
      container
      className='demo-auth-footer position-relative bottom-0 start-0 w-100 py-2 text-center mt-4 justify-content-center align-items-center d-flex flex-column'
    >
      <Grid container item justifyContent='center' spacing={4}>
        <Grid item>
          <Link href={LINKS.contact} className='text-inherit text-decoration-none'>
            {t('footerLinks.support')}
          </Link>
        </Grid>
        <Grid item>
          <Link href={LINKS.privaceProlicy} className='text-inherit text-decoration-none'>
            {t('footerLinks.privacyPolicy')}
          </Link>
        </Grid>
        <Grid item>
          <Link href={LINKS.termsAndConditions} className='text-inherit text-decoration-none'>
            {t('footerLinks.termsAndConditions')}
          </Link>
        </Grid>
      </Grid>
      <Grid item sx={{ mt: 2 }}>
        <Typography variant='body1'>{t('footerLinks.businessKitz')}</Typography>
      </Grid>
    </Grid>
  );
};

export default DemoAuthFooter;
