import React from 'react';
import BusinessKitzLogo from '../../../static/assets/svg/business-kitz-logo.svg';
import { Grid } from '@mui/material';
import DemoAuthFooter from '../demo-auth-footer/DemoAuthFooter';

interface DemoAuthTemplateProps {
  featureItems?: JSX.Element;
}

const DemoAuthTemplate = (props: DemoAuthTemplateProps) => {
  return (
    <div className='demo-auth-template'>
      <header>
        <Grid container>
          <Grid item xs={12} className='demo-auth-template-logo-item'>
            <BusinessKitzLogo />
          </Grid>
        </Grid>
      </header>
      <Grid
        container
        className='demo-auth-template-container d-flex justify-content-center align-items-center flex-grow-1 px-2'
      >
        {props.featureItems && (
          <Grid item xs={12} sm={6} md={6} lg={4} sx={{ p: 3 }}>
            {props.featureItems}
          </Grid>
        )}
        <Grid // TODO: This component will be removed by clerck iframe
          item
          xs={12}
          sm={6}
          md={6}
          lg={4}
          sx={{
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            border: '1px solid #ddd',
            minHeight: '60vh',
          }}
        >
          Clerk login
        </Grid>
      </Grid>
      <DemoAuthFooter />
    </div>
  );
};

export default DemoAuthTemplate;
