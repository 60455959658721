import React, { useEffect, useState } from 'react';
import { Box, Paper, Select, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { StyledTableCell } from './components/TableCell';
import { StyledTableRow } from './components/TableRow';
import { TableHeader } from './components/TableHeader';
import { Template } from '../../../../models/template.model';
import { ActionsCell } from './components/ActionsCell';
import { HTMLTemplateModel } from '../../../../models/html.template.model';
import { DocumentTemplateModel } from '../../../../models/document.template.model';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants/routes';
import { Helpers } from '../../../../common/helpers/helpers';
import TableHead from '@mui/material/TableHead';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { TAGS_LABELS } from '../../../../common/constants/tags-labels';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { fetchCompanies, selectAllCompanies } from '../../../../slices/companies/companiesSlice';
import StarIcon from '@mui/icons-material/Star';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import PlanUpgradingMessage from '../../../../common/plan-upgrading-message/PlanUpgradingMessage';

export type TemplatesTableProps = {
  templates: Template[] | HTMLTemplateModel[] | DocumentTemplateModel[];
};

export const TemplatesTable = ({ templates }: TemplatesTableProps) => {
  const { name } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const defaultValue = 'all';
  const documentTypeLabelsSorted = TAGS_LABELS.documentType
    .map((el) => t(el))
    .sort((a, b) => a.localeCompare(b));
  const categoryLabelsSorted = TAGS_LABELS.categories
    .map((el) => t(el))
    .sort((a, b) => a.localeCompare(b));
  const [documentTypeLabel, setDocumentTypeLabel] = useState<string>(defaultValue);
  const [documentWorkflowLabel, setDocumentWorkflowLabel] = useState<string>(defaultValue);
  const [categoryLabel, setCategoryLabel] = useState<string>(defaultValue);
  const [planUpgradingMessage, setPlanUpgradingMessage] = useState<boolean>(false);
  const company = useAppSelector(selectAllCompanies)[0];
  const documentWorkflowLabels = () => {
    switch (categoryLabel) {
      case 'all':
        return [
          ...TAGS_LABELS.documentWorkflow.employment.map((el) => t(el)),
          ...TAGS_LABELS.documentWorkflow.company.map((el) => t(el)),
          ...TAGS_LABELS.documentWorkflow.customer.map((el) => t(el)),
          ...TAGS_LABELS.documentWorkflow.supplier.map((el) => t(el)),
        ];
      case t('tagsLabels.categories.employment'):
        return [...TAGS_LABELS.documentWorkflow.employment.map((el) => t(el))];
      case t('tagsLabels.categories.business'):
        return [...TAGS_LABELS.documentWorkflow.company.map((el) => t(el))];
      case t('tagsLabels.categories.customers'):
        return [...TAGS_LABELS.documentWorkflow.customer.map((el) => t(el))];
      case t('tagsLabels.categories.suppliers'):
        return [...TAGS_LABELS.documentWorkflow.supplier.map((el) => t(el))];
    }
  };
  const documentWorkflowLabelsSorted = [...new Set(documentWorkflowLabels())].sort((a, b) =>
    a.localeCompare(b)
  );

  const checkViewOnClickEnabled = (row: HTMLTemplateModel | Template | DocumentTemplateModel) =>
    'document_upload' in row ||
    ((name === 'uk' || name === 'au') && 'download_link' in row && !row.download_link);

  const navigate = useNavigate();
  const filtering = (el: HTMLTemplateModel) => {
    const itemsFilteredByCategoryLabel = el.dl_categories
      .map((item) => item.toLowerCase().trim())
      .includes(categoryLabel.toLowerCase());
    const itemsFilteredByDocumentTypeLabel = el.dl_types
      .map((item) => item.toLowerCase().trim())
      .includes(documentTypeLabel.toLowerCase());
    const itemsFilteredByDocumentWorkflowLabel = el.dl_workflows
      .map((item) => item.toLowerCase().trim())
      .includes(documentWorkflowLabel.toLowerCase());
    if (
      categoryLabel !== defaultValue &&
      documentTypeLabel === defaultValue &&
      documentWorkflowLabel === defaultValue
    ) {
      return itemsFilteredByCategoryLabel;
    } else if (
      categoryLabel === defaultValue &&
      documentTypeLabel !== defaultValue &&
      documentWorkflowLabel === defaultValue
    ) {
      return itemsFilteredByDocumentTypeLabel;
    } else if (
      categoryLabel === defaultValue &&
      documentTypeLabel === defaultValue &&
      documentWorkflowLabel !== defaultValue
    ) {
      return itemsFilteredByDocumentWorkflowLabel;
    } else if (
      categoryLabel !== defaultValue &&
      documentTypeLabel !== defaultValue &&
      documentWorkflowLabel === defaultValue
    ) {
      return itemsFilteredByCategoryLabel && itemsFilteredByDocumentTypeLabel;
    } else if (
      categoryLabel !== defaultValue &&
      documentTypeLabel === defaultValue &&
      documentWorkflowLabel !== defaultValue
    ) {
      return itemsFilteredByCategoryLabel && itemsFilteredByDocumentWorkflowLabel;
    } else if (
      categoryLabel === defaultValue &&
      documentTypeLabel !== defaultValue &&
      documentWorkflowLabel !== defaultValue
    ) {
      return itemsFilteredByDocumentTypeLabel && itemsFilteredByDocumentWorkflowLabel;
    } else if (
      categoryLabel !== defaultValue &&
      documentTypeLabel !== defaultValue &&
      documentWorkflowLabel !== defaultValue
    ) {
      return (
        itemsFilteredByCategoryLabel &&
        itemsFilteredByDocumentTypeLabel &&
        itemsFilteredByDocumentWorkflowLabel
      );
    }
  };
  const filteredTemplates =
    categoryLabel !== defaultValue ||
    documentTypeLabel !== defaultValue ||
    documentWorkflowLabel !== defaultValue
      ? [...(templates as HTMLTemplateModel[])].filter((el) => filtering(el))
      : templates;

  useEffect(() => {
    if (!company) {
      dispatch(fetchCompanies())
        .unwrap()
        .then((res) => {
          if (res.length === 0) {
            navigate(ROUTES.COMPANY_CREATE);
          }
        });
    }
  }, []);

  return templates.length > 0 ? (
    <>
      {(name === 'uk' || name === 'au') && (
        <div className='d-lg-flex justify-content-lg-between align-items-lg-start w-100 mt-5 mb-5 mb-lg-2 table-filtering-block'>
          <div className='d-lg-flex col-lg-11 mb-lg-2'>
            <div className='col-lg-3 flex-lg-shrink-0 me-2 mb-2'>
              <label htmlFor='categoryLabelSelect'>{t('labels.profile')}</label>
              <Select
                id='categoryLabelSelect'
                value={categoryLabel}
                className='w-100'
                onChange={(event: SelectChangeEvent) => {
                  setCategoryLabel(event.target.value as string);
                  if (documentWorkflowLabel !== defaultValue) {
                    setDocumentWorkflowLabel(defaultValue);
                  }
                }}
              >
                <MenuItem value={'all'}>{t('labels.showAll')}</MenuItem>
                {categoryLabelsSorted.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className='col-lg-3 flex-lg-shrink-0 me-2 mb-2'>
              <label htmlFor='documentWorkflowSelect'>{t('labels.documentWorkflow')}</label>
              <Select
                id='documentWorkflowSelect'
                value={documentWorkflowLabel}
                className='w-100'
                onChange={(event: SelectChangeEvent) => {
                  setDocumentWorkflowLabel(event.target.value as string);
                }}
              >
                <MenuItem value={'all'}>{t('labels.showAll')}</MenuItem>
                {documentWorkflowLabelsSorted.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className='col-lg-3 flex-lg-shrink-0 me-2 mb-2'>
              <label htmlFor='documentTypeSelect'>{t('labels.documentType')}</label>
              <Select
                id='documentTypeSelect'
                value={documentTypeLabel}
                className='w-100'
                onChange={(event: SelectChangeEvent) => {
                  setDocumentTypeLabel(event.target.value as string);
                }}
              >
                <MenuItem value={'all'}>{t('labels.showAll')}</MenuItem>
                {documentTypeLabelsSorted.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className='d-flex mb-lg-2 justify-content-end' style={{ paddingTop: '1.5rem' }}>
            <Tooltip title={t('buttons.resetFilters')} placement='top' arrow>
              <span className='d-block'>
                <IconButton
                  aria-label={t('buttons.resetFilters')}
                  disabled={
                    documentTypeLabel === defaultValue &&
                    documentWorkflowLabel === defaultValue &&
                    categoryLabel === defaultValue
                  }
                  size='medium'
                  onClick={() => {
                    setDocumentTypeLabel(defaultValue);
                    setDocumentWorkflowLabel(defaultValue);
                    setCategoryLabel(defaultValue);
                  }}
                >
                  <RotateLeftOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </div>
      )}
      <TableContainer
        elevation={0}
        sx={{ py: 2, px: 3, borderRadius: 5 }}
        variant='outlined'
        component={Paper}
      >
        <Table aria-label='templates table'>
          {name === 'uk' || name === 'au' ? (
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('templatesTable.nameColumnLabel')}</StyledTableCell>
                <StyledTableCell style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                  {t('labels.profile')}
                </StyledTableCell>
                <StyledTableCell style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                  {t('labels.documentWorkflow')}
                </StyledTableCell>
                <StyledTableCell style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                  {t('labels.documentType')}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {t('templatesTable.actionColumnLabel')}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          ) : (
            <TableHeader />
          )}
          <TableBody sx={{ px: 2 }}>
            {(name === 'au' || name === 'uk' ? filteredTemplates : templates).map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell
                  onClick={() => {
                    if (checkViewOnClickEnabled(row)) {
                      if (
                        'is_free' in row &&
                        !row.is_free &&
                        !company?.stripe_product_id &&
                        !company?.plan_settings.stripe_product_id
                      ) {
                        setPlanUpgradingMessage(true);
                      } else {
                        navigate(
                          `${ROUTES.DOCUMENT_LIBRARY}/${name}/${row.id}?templateViewEditMode=true`
                        );
                      }
                    }
                  }}
                >
                  <div className='templates-table-name d-flex align-items-center'>
                    {row.name}
                    {'is_free' in row &&
                    row.is_free &&
                    !company?.stripe_product_id &&
                    !company?.plan_settings.stripe_product_id ? (
                      <span className='file-type-label free ms-1'>{t('labels.free')}</span>
                    ) : null}
                  </div>
                </StyledTableCell>
                {name === 'au' || name === 'uk' ? (
                  <>
                    <StyledTableCell
                      onClick={() => {
                        if (checkViewOnClickEnabled(row)) {
                          if (
                            'is_free' in row &&
                            !row.is_free &&
                            !company?.stripe_product_id &&
                            !company?.plan_settings.stripe_product_id
                          ) {
                            setPlanUpgradingMessage(true);
                          } else {
                            navigate(
                              `${ROUTES.DOCUMENT_LIBRARY}/${name}/${row.id}?templateViewEditMode=true`
                            );
                          }
                        }
                      }}
                    >
                      {'dl_categories' in row && row.dl_categories.length > 0
                        ? row.dl_categories.map((el) => (
                            <div key={el} className='text-capitalize'>
                              {el}
                            </div>
                          ))
                        : null}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() => {
                        if (checkViewOnClickEnabled(row)) {
                          if (
                            'is_free' in row &&
                            !row.is_free &&
                            !company?.stripe_product_id &&
                            !company?.plan_settings.stripe_product_id
                          ) {
                            setPlanUpgradingMessage(true);
                          } else {
                            navigate(
                              `${ROUTES.DOCUMENT_LIBRARY}/${name}/${row.id}?templateViewEditMode=true`
                            );
                          }
                        }
                      }}
                    >
                      {'dl_workflows' in row && row.dl_workflows.length > 0
                        ? row.dl_workflows.map((el) => <div key={el}>{el}</div>)
                        : null}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() => {
                        if (checkViewOnClickEnabled(row)) {
                          if (
                            'is_free' in row &&
                            !row.is_free &&
                            !company?.stripe_product_id &&
                            !company?.plan_settings.stripe_product_id
                          ) {
                            setPlanUpgradingMessage(true);
                          } else {
                            navigate(
                              `${ROUTES.DOCUMENT_LIBRARY}/${name}/${row.id}?templateViewEditMode=true`
                            );
                          }
                        }
                      }}
                    >
                      {'dl_types' in row && row.dl_types.length > 0
                        ? row.dl_types.map((el) => <div key={el}>{el}</div>)
                        : null}
                    </StyledTableCell>
                  </>
                ) : null}
                {name === 'company-templates' && (
                  <StyledTableCell>
                    <div className='date-time'>
                      {'updated' in row && Helpers.timeStampToDate(row.updated)}
                    </div>
                  </StyledTableCell>
                )}
                <ActionsCell align='right' row={row} />
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {planUpgradingMessage && (
        <PlanUpgradingMessage
          open={planUpgradingMessage}
          text={t('messages.considerStartingPlanLibrary')}
          handleClose={() => setPlanUpgradingMessage(false)}
        />
      )}
    </>
  ) : null;
};
