import React from 'react';
import StartPageTemplate from '../../common/start-page-template/StartPageTemplate';
import FeaturesContentLogIn from './FeaturesContentLogIn';
import LogInForm from './LogInForm';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { useTranslation } from 'react-i18next';
import ClerkLogIn from './ClerkLogIn';

const LogIn = () => {
  const { t } = useTranslation();
  const FormContent = () => {
    return (
      <div className='features-container'>
        <div hidden={true}>
          <LogInForm />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ClerkLogIn />
        </div>
      </div>
    );
  };
  const BrandContent = () => {
    return (
      <div className='features-container'>
        <FeaturesContentLogIn />
      </div>
    );
  };
  return (
    <StartPageTemplate
      brandContainerContent={<BrandContent />}
      secondaryContainerContent={<FormContent />}
    />
  );
};

export default LogIn;
