import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { TextField, Button, Box } from '@mui/material';
import { addTodo, updateTodo } from '../../../../slices/todo/todoSlice';
import { selectUser } from '../../../../slices/user/userSlice';
import { useSelector } from 'react-redux';

interface AddTodoFormProps {
  existingTodo?: {
    id: number;
    message: string;
    due_at: string | null;
    started_at: string | null;
    completed_at: string | null;
  };
}

const AddTodoForm = ({ existingTodo }: AddTodoFormProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [message, setMessage] = useState('');
  const [dueAt, setDueAt] = useState<string | null>(null);
  const [startedAt, setStartedAt] = useState<string | null>(null);
  const [completedAt, setCompletedAt] = useState<string | null>(null);

  useEffect(() => {
    if (existingTodo) {
      setMessage(existingTodo.message);
      setDueAt(existingTodo.due_at);
    }
  }, [existingTodo]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const todoData = {
      user: user.id,
      message,
      due_at: dueAt,
      started_at: startedAt,
      completed_at: completedAt,
    };

    if (existingTodo) {
      dispatch(updateTodo({ id: existingTodo.id, data: todoData }));
    } else {
      dispatch(addTodo({ data: todoData }));
    }

    setMessage('');
    setDueAt('');
    setStartedAt(null);
    setCompletedAt(null);
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <TextField
        label='Message'
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      />
      <TextField
        label='Due Date'
        type='datetime-local'
        InputLabelProps={{ shrink: true }}
        value={dueAt || ''}
        onChange={(e) => setDueAt(e.target.value)}
        required
      />

      <Button variant='contained' type='submit'>
        {existingTodo ? 'Update ToDo' : 'Add ToDo'}
      </Button>
    </Box>
  );
};

export default AddTodoForm;
