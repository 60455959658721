import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { Employee, EmployeeSearchData } from '../../models/employee.model';
import { RootState } from '../../store';

export const fetchEmployees = createAsyncThunk(
  'companies/fetchEmployees',
  async ({ company_id }: EmployeeSearchData, { rejectWithValue }) => {
    if (company_id === undefined) {
      return rejectWithValue('Company ID is required');
    }
    const response = await NWClient.list('employee', { company_id });
    return response;
  }
);

export interface EmployeesState {
  employeesList: Employee[];
  status: string;
  error: string;
}

const employeesArray: Employee[] = [];
const initialState: EmployeesState = { employeesList: employeesArray, status: 'idle', error: '' };
const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.employeesList = [...action.payload];
          state.status = 'succeeded';
        }
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default employeesSlice.reducer;

export const selectAllEmployees = (state: RootState) => state.employees.employeesList;
export const selectEmployeeById = (state: RootState, employeeId: number) =>
  state.employees.employeesList.find((item) => item.id === employeeId);
