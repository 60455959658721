import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, IconButton, Toolbar, Menu, MenuItem, Grid } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { NwBreadCrumbs } from '../nw-breadcrumbs';
import { UserButton } from '@clerk/clerk-react';
import { NWClient } from '../../client/NWClient';

import { ROUTES } from '../constants/routes';
import { logout } from '../../slices/user/userSlice';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { ProgressOverlay } from '../progress-overlay/ProgressOverlay';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useAppSelector } from '../hooks/useAppSelector';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';

const NWAppBar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const designUpdated = useAppSelector((state) => state.designUpdated.designUpdated);
  const [pendingStatus, setPendingStatus] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(switchDesign(event.target.checked));
  };

  return (
    <>
      <AppBar
        position='static'
        color='transparent'
        className='nw-app-bar'
        variant='outlined'
        elevation={0}
      >
        <Toolbar className='nw-app-bar-toolbar'>
          <Grid className='content'>
            <Grid>
              <NwBreadCrumbs />
            </Grid>
            <Grid className='right'>
              {path === ROUTES.INDEX && process.env.ENV_TYPE !== 'PROD' && (
                <FormControlLabel
                  control={<Switch checked={designUpdated} onChange={handleChange} />}
                  label={'Updated design version'}
                />
              )}
              <UserButton />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default NWAppBar;
