import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import { ClerkSignedIn } from '../clerk-components/index';

type ProtectedRouteProps = {
  children: React.ReactNode; // Type for children
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isSignedIn, isLoaded } = useAuth();

  if (!isLoaded) {
    return null;
  }

  // Redirect to login page if not signed in
  if (!isSignedIn) {
    return <Navigate to='/login' replace />;
  }

  return <ClerkSignedIn>{children}</ClerkSignedIn>;
};

export default ProtectedRoute;
