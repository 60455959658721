import React, { useEffect, useState, useRef, ChangeEvent } from 'react';
import Brand from '../../../static/assets/svg/brand.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {
  fetchDocuments,
  selectAllDocuments,
  uploadDocument,
} from '../../slices/documents/documentsSlice';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { Box, Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { selectUser } from '../../slices/user/userSlice';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { StyledTableRow } from '../category/components/TemplatesTable/components/TableRow';
import { StyledTableCell } from '../category/components/TemplatesTable/components/TableCell';
import { ActionsCell } from './components/ActionsCell';
import { DocumentTemplateModel } from '../../models/document.template.model';
import { NWClient } from '../../client/NWClient';
import {
  fetchDocumentContracts,
  selectAllDocumentContracts,
} from '../../slices/document-contracts/documentContractsSlice';
import { DocumentVaultItemModel } from '../../models/document.vault.item.model';
import { Helpers } from '../../common/helpers/helpers';
import { AIDocumentContractModelWithId } from '../../models/ai.document.contract.model';
import InfoLogo from '../../../static/assets/svg/disclaimer-info.svg';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { TAGS_LABELS } from '../../common/constants/tags-labels';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PopoverWithButton from '../../common/popover-with-button/PopoverWithButton';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import IconButton from '@mui/material/IconButton';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import { use } from 'i18next';
import { fetchEmployees, selectAllEmployees } from '../../slices/employees/employeesSlice';
import { fetchSuppliers, selectAllSuppliers } from '../../slices/suppliers/suppliersSlice';
import { fetchCustomers, selectAllCustomers } from '../../slices/customers/customersSlice';

const DocumentsCategoryPage = () => {
  const company = useAppSelector(selectAllCompanies)[0];
  const employees = useAppSelector(selectAllEmployees);
  const suppliers = useAppSelector(selectAllSuppliers);
  const customers = useAppSelector(selectAllCustomers);
  const { category } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const documents = useAppSelector(selectAllDocuments);
  const documentContracts = useAppSelector(selectAllDocumentContracts);
  const currentUser = useAppSelector(selectUser);
  const [documentTypeLabel, setDocumentTypeLabel] = useState([]);
  const [profileNameLabel, setProfileNameLabel] = useState([]);
  const [documentWorkflowLabel, setDocumentWorkflowLabel] = useState([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [uploadControlOpened, setUploadControlOpened] = useState<boolean>(true);
  const [templates, setTemplates] = useState<DocumentTemplateModel[]>([]);
  const [sorting, setSorting] = useState<'asc' | 'desc'>('asc');
  const [sortingBy, setSortingBy] = useState<'date' | 'name' | 'fileType'>('date');

  const [employeeProfileNameLabels, setEmployeeProfileNameLabels] = useState<string[]>([]);
  const [supplierProfileNameLabels, setSupplierProfileNameLabels] = useState<string[]>([]);
  const [companyProfileNameLabels, setCompanyProfileNameLabels] = useState<string[]>([]);
  const [customerProfileNameLabels, setCustomerProfileNameLabels] = useState<string[]>([]);
  const documentTypeLabels = TAGS_LABELS.documentType.map((el) => t(el));
  const documentTypeLabelsSorted = documentTypeLabels
    .sort((a, b) => a.localeCompare(b))
    .map((el) => ({
      title: el,
      value: el,
    }));
  const documentWorkflowLabelsEmployment = TAGS_LABELS.documentWorkflow.employment.map((el) =>
    t(el)
  );
  const documentWorkflowLabelsEmploymentSorted = documentWorkflowLabelsEmployment.sort((a, b) =>
    a.localeCompare(b)
  );
  const documentWorkflowLabelsSupplier = TAGS_LABELS.documentWorkflow.supplier.map((el) => t(el));
  const documentWorkflowLabelsSupplierSorted = documentWorkflowLabelsSupplier.sort((a, b) =>
    a.localeCompare(b)
  );
  const documentWorkflowLabelsCustomer = TAGS_LABELS.documentWorkflow.customer.map((el) => t(el));
  const documentWorkflowLabelsCustomerSorted = documentWorkflowLabelsCustomer.sort((a, b) =>
    a.localeCompare(b)
  );
  const documentWorkflowLabelsCompany = TAGS_LABELS.documentWorkflow.company.map((el) => t(el));
  const documentWorkflowLabelsCompanySorted = documentWorkflowLabelsCompany.sort((a, b) =>
    a.localeCompare(b)
  );

  const icon = <CheckBoxOutlineBlankIcon fontSize='medium' />;
  const checkedIcon = <CheckBoxIcon fontSize='medium' />;

  const getWorkflowLabels = () => {
    switch (category) {
      case 'employment':
        return documentWorkflowLabelsEmploymentSorted.map((el) => ({ title: el, value: el }));
      case 'suppliers':
        return documentWorkflowLabelsSupplierSorted.map((el) => ({ title: el, value: el }));
      case 'company':
        return documentWorkflowLabelsCompanySorted.map((el) => ({ title: el, value: el }));
      case 'customers':
        return documentWorkflowLabelsCustomerSorted.map((el) => ({ title: el, value: el }));
      default:
        return [];
    }
  };

  const getFileTypeFromName = (row: { name?: string; contract_generation?: number }) => {
    const lastCharacters = row.name?.slice(-4);
    if (row.contract_generation === undefined) {
      if (lastCharacters.includes('.')) {
        const charactersWithoutDot = lastCharacters.replace('.', '');
        return charactersWithoutDot;
      }
      return lastCharacters;
    } else {
      return 'bk';
    }
  };
  const documentsFiltered =
    category === 'templates'
      ? templates
      : documents.filter((el) => el.category === category && el.user === currentUser.id);
  const documentContractsFiltered = documentContracts.filter(
    (el) => el.category === category && el.user === currentUser.id
  );
  const itemsComposed: (
    | DocumentTemplateModel
    | AIDocumentContractModelWithId
    | DocumentVaultItemModel
  )[] = [...documentsFiltered, ...documentContractsFiltered].map((el) => {
    return {
      ...el,
      fileType: el.fileType && el.fileType.length > 0 ? el.fileType[0] : getFileTypeFromName(el),
    };
  });

  const filtering = (el: any) => {
    const startDateCondition = new Date(el.updated) >= new Date(startDate?.toISOString());
    const endDateCondition =
      new Date(new Date(el.updated).setHours(0, 0, 0, 0)) <= new Date(endDate?.toISOString());
    const documentTypeLabelConditionDU = documentTypeLabel.every((item) =>
      el.du_types?.includes(item.value)
    );
    const documentTypeLabelConditionDC = documentTypeLabel.every((item) =>
      el.dc_types?.includes(item.value)
    );
    const documentWorkflowLabelConditionDU = documentWorkflowLabel.every((item) =>
      el.du_workflows?.includes(item.value)
    );
    const documentWorkflowLabelConditionDC = documentWorkflowLabel.every((item) =>
      el.dc_workflows?.includes(item.value)
    );

    if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length === 0 &&
      !startDate &&
      !endDate
    ) {
      if ('du_types' in el) {
        return documentTypeLabelConditionDU;
      } else if ('dc_types' in el) {
        return documentTypeLabelConditionDC;
      }
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length > 0 &&
      !startDate &&
      !endDate
    ) {
      if ('du_workflows' in el) {
        return documentWorkflowLabelConditionDU;
      } else if ('dc_workflows' in el) {
        return documentWorkflowLabelConditionDC;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length > 0 &&
      !startDate &&
      !endDate
    ) {
      if ('du_types' in el && 'du_workflows' in el) {
        return documentTypeLabelConditionDU && documentWorkflowLabelConditionDU;
      } else if ('dc_types' in el && 'dc_workflows' in el) {
        return documentTypeLabelConditionDC && documentWorkflowLabelConditionDC;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length === 0 &&
      startDate &&
      !endDate
    ) {
      if ('du_types' in el) {
        return documentTypeLabelConditionDU && startDateCondition;
      } else if ('dc_types' in el) {
        return documentTypeLabelConditionDC && startDateCondition;
      }
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length > 0 &&
      startDate &&
      !endDate
    ) {
      if ('du_workflows' in el) {
        return documentWorkflowLabelConditionDU && startDateCondition;
      } else if ('dc_workflows' in el) {
        return documentWorkflowLabelConditionDC && startDateCondition;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length > 0 &&
      startDate &&
      !endDate
    ) {
      if ('du_types' in el && 'du_workflows' in el) {
        return (
          documentTypeLabelConditionDU && documentWorkflowLabelConditionDU && startDateCondition
        );
      } else if ('dc_types' in el && 'dc_workflows' in el) {
        return (
          documentTypeLabelConditionDC && documentWorkflowLabelConditionDC && startDateCondition
        );
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length === 0 &&
      !startDate &&
      endDate
    ) {
      if ('du_types' in el) {
        return documentTypeLabelConditionDU && endDateCondition;
      } else if ('dc_types' in el) {
        return documentTypeLabelConditionDC && endDateCondition;
      }
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length > 0 &&
      !startDate &&
      endDate
    ) {
      if ('du_workflows' in el) {
        return documentWorkflowLabelConditionDU && endDateCondition;
      } else if ('dc_workflows' in el) {
        return documentWorkflowLabelConditionDC && endDateCondition;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length > 0 &&
      !startDate &&
      endDate
    ) {
      if ('du_types' in el && 'du_workflows' in el) {
        return documentTypeLabelConditionDU && documentWorkflowLabelConditionDU && endDateCondition;
      } else if ('dc_types' in el && 'dc_workflows' in el) {
        return documentTypeLabelConditionDC && documentWorkflowLabelConditionDC && endDateCondition;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length === 0 &&
      startDate &&
      endDate
    ) {
      if ('du_types' in el) {
        return documentTypeLabelConditionDU && startDateCondition && endDateCondition;
      } else if ('dc_types' in el) {
        return documentTypeLabelConditionDC && startDateCondition && endDateCondition;
      }
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length > 0 &&
      startDate &&
      endDate
    ) {
      if ('du_workflows' in el) {
        return documentWorkflowLabelConditionDU && startDateCondition && endDateCondition;
      } else if ('dc_workflows' in el) {
        return documentWorkflowLabelConditionDC && startDateCondition && endDateCondition;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length > 0 &&
      startDate &&
      endDate
    ) {
      if ('du_types' in el && 'du_workflows' in el) {
        return (
          documentTypeLabelConditionDU &&
          documentWorkflowLabelConditionDU &&
          startDateCondition &&
          endDateCondition
        );
      } else if ('dc_types' in el && 'dc_workflows' in el) {
        return (
          documentTypeLabelConditionDC &&
          documentWorkflowLabelConditionDC &&
          startDateCondition &&
          endDateCondition
        );
      }
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length === 0 &&
      startDate &&
      !endDate
    ) {
      return startDateCondition;
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length === 0 &&
      !startDate &&
      endDate
    ) {
      return endDateCondition;
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length === 0 &&
      startDate &&
      endDate
    ) {
      return startDateCondition && endDateCondition;
    }
  };
  const sortByDate = (a: any, b: any) => {
    return (
      Number(new Date((sorting === 'asc' ? b : a).updated)) -
      Number(new Date((sorting === 'asc' ? a : b).updated))
    );
  };
  const sortByName = (a: any, b: any) => {
    return (sorting === 'asc' ? a : b).name.localeCompare((sorting === 'asc' ? b : a).name);
  };
  const sortByFileType = (a: any, b: any) => {
    return (sorting === 'asc' ? a : b).fileType.localeCompare((sorting === 'asc' ? b : a).fileType);
  };
  const sortingCondition =
    documentTypeLabel.length > 0 || documentWorkflowLabel.length > 0 || startDate || endDate;
  const itemsSortedByDate = sortingCondition
    ? [...itemsComposed].filter((el) => filtering(el)).sort((a, b) => sortByDate(a, b))
    : [...itemsComposed].sort((a, b) => sortByDate(a, b));
  const itemsSortedByName = sortingCondition
    ? [...itemsComposed].filter((el) => filtering(el)).sort((a, b) => sortByName(a, b))
    : [...itemsComposed].sort((a, b) => sortByName(a, b));
  const itemsSortedByFileType = sortingCondition
    ? [...itemsComposed].filter((el) => filtering(el)).sort((a, b) => sortByFileType(a, b))
    : [...itemsComposed].sort((a, b) => sortByFileType(a, b));

  const handleSorting = (sortingValue: 'name' | 'fileType' | 'date') => {
    if (sortingValue !== sortingBy) {
      setSortingBy(sortingValue);
      setSorting('asc');
    } else {
      if (sorting === 'asc') {
        setSorting('desc');
      } else {
        setSorting('asc');
      }
    }
  };

  const getItemsArray = () => {
    switch (sortingBy) {
      case 'name':
        return itemsSortedByName;
      case 'date':
        return itemsSortedByDate;
      case 'fileType':
        return itemsSortedByFileType;
    }
  };

  const uploadDocumentFn = () => {
    setPendingStatus(true);
    const dataTransfer = new DataTransfer();
    uploadedFiles.forEach((file) => {
      dataTransfer.items.add(file);
    });
    formRef.current.querySelector('[type="file"]').files = dataTransfer.files;
    // Help Safari out
    if (formRef.current.querySelector('[type="file"]').webkitEntries.length) {
      formRef.current.querySelector('[type="file"]').dataset.file = `${dataTransfer.files[0].name}`;
    }
    const data = new FormData(formRef.current);
    data.set('user', String(currentUser.id));
    data.set('category', category);
    dispatch(uploadDocument({ data }))
      .unwrap()
      .then((res: DocumentVaultItemModel) => {
        setUploadedFiles([]);
        if (formRef && formRef.current) {
          formRef.current.reset();
        }
        NWClient.post('document-upload-html', { document_upload: res.id })
          .then(() => {
            toast.success(t('messages.documentUploaded'), { theme: 'colored' });
          })
          .catch((error) => {
            toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
          })
          .finally(() => {
            setPendingStatus(false);
          });
      })
      .catch((error) => {
        toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
        setPendingStatus(false);
      });
  };
  const setUploadButtonText = () => {
    if ((!uploadControlOpened && documentsFiltered.length > 0) || documentsFiltered.length === 0) {
      return t('buttons.upload');
    } else if (uploadControlOpened && documentsFiltered.length > 0) {
      return t('buttons.save');
    }
  };
  const handleUploadButtonClick = () => {
    if (uploadControlOpened || documentsFiltered.length === 0) {
      uploadDocumentFn();
    } else {
      setUploadControlOpened(true);
    }
  };

  const handleNavigateByRowClick = (row: any) => {
    navigate(
      category === 'company-templates'
        ? `${pathName}/${row.id}?initialTab=1`
        : `${pathName}/${row.id}${
            row.contract_generation ? '?initialTab=1&contractGenerated=true' : ''
          }`
    );
  };

  const fetchEmployeeProfiles = async () => {
    try {
      const result = await dispatch(fetchEmployees({ company_id: company.id })).unwrap();
      const employeeNames = employees.map(
        (employee: any) => employee.first_name + ' ' + employee.last_name
      );
      setEmployeeProfileNameLabels(employeeNames.sort((a: string, b: any) => a.localeCompare(b)));
    } catch (error) {
      console.error('Error fetching employee profiles:', error);
    }
  };

  const fetchSupplierProfiles = async () => {
    try {
      const result = await dispatch(fetchSuppliers({ company_id: company.id })).unwrap();
      const supplierNames = suppliers.map((supplier: any) => supplier.name);
      setSupplierProfileNameLabels(supplierNames.sort((a: string, b: any) => a.localeCompare(b)));
    } catch (error) {
      console.error('Error fetching supplier profiles:', error);
    }
  };

  const fetchCompanyProfiles = async () => {
    setCompanyProfileNameLabels([company?.name]);
  };

  const fetchCustomerProfiles = async () => {
    try {
      const result = await dispatch(fetchCustomers({ company_id: company.id })).unwrap();
      const customerNames = customers.map((customer: any) => customer.name);
      setCustomerProfileNameLabels(customerNames.sort((a: string, b: any) => a.localeCompare(b)));
    } catch (error) {
      console.error('Error fetching customer profiles:', error);
    }
  };

  useEffect(() => {
    switch (category) {
      case 'employment':
        fetchEmployeeProfiles();
        break;

      case 'suppliers':
        fetchSupplierProfiles();
        break;
      case 'company':
        fetchCompanyProfiles();
        break;

      case 'customers':
        fetchCustomerProfiles();
        break;
      default:
        break;
    }
  }, [category, company]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchDocuments({ data: { user: currentUser.id, category } }))
      .unwrap()
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
    dispatch(fetchDocumentContracts({ data: { user: currentUser.id, category } }))
      .unwrap()
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getProfileNameLabels = () => {
    switch (category) {
      case 'employment':
        return employeeProfileNameLabels.map((el) => ({ title: el, value: el }));
      case 'supplier':
        return supplierProfileNameLabels.map((el) => ({ title: el, value: el }));
      case 'business':
        return companyProfileNameLabels.map((el) => ({ title: el, value: el }));
      case 'customer':
        return customerProfileNameLabels.map((el) => ({ title: el, value: el }));
      default:
        return [];
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
        <h1 className='text-capitalize text-center mb-3'>{category}</h1>
      </div>
      {loading ? (
        <div className='text-center'>
          <ProgressOverlay className='position-relative' />
        </div>
      ) : (
        <>
          {category !== 'templates' && (
            <>
              <Dropzone
                accept={{
                  'application/msword': ['.doc'],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                    '.docx',
                  ],
                  'application/pdf': ['.pdf'],
                }}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles(acceptedFiles);
                }}
                maxFiles={1}
              >
                {({ getRootProps, getInputProps }) => (
                  <form ref={formRef}>
                    <div className='dropzone-wrapper'>
                      <div {...getRootProps()}>
                        <input
                          name='document_file'
                          ref={inputRef}
                          type='file'
                          title='Upload a file'
                          {...getInputProps()}
                          multiple={false}
                        />
                        <UploadFileIcon fontSize='large' />
                        <p className='dropzone-message'>
                          <span className='link-style'>
                            {uploadedFiles.length > 0 ? '' : t('messages.clickToUpload') + '.'}
                          </span>
                        </p>
                      </div>
                      {uploadedFiles.length > 0 && (
                        <div className='success'>
                          {/*t('labels.uploadedFile')*/}
                          {uploadedFiles.map((el) => (
                            <span key={el.name}>{el.name}</span>
                          ))}
                        </div>
                      )}
                    </div>
                  </form>
                )}
              </Dropzone>

              <div className='buttons-row d-flex justify-content-end align-items-center mb-3 mt-3'>
                {uploadedFiles.length > 0 && (
                  <Button
                    role='button'
                    type='button'
                    onClick={() => {
                      setUploadedFiles([]);
                      formRef.current.reset();
                    }}
                  >
                    {t('buttons.reset')}
                  </Button>
                )}
                <Button
                  style={{ marginLeft: '0.5rem' }}
                  role='button'
                  disabled={
                    documentsFiltered.length === 0
                      ? uploadedFiles.length === 0
                      : uploadedFiles.length === 0 && uploadControlOpened
                  }
                  variant='contained'
                  onClick={() => handleUploadButtonClick()}
                >
                  {setUploadButtonText()}
                </Button>
              </div>
            </>
          )}

          {documentsFiltered.length > 0 || documentContractsFiltered.length > 0 ? (
            <>
              {((documentsFiltered.length > 0 && documentContractsFiltered.length > 0) ||
                documentsFiltered.length > 1 ||
                documentContractsFiltered.length > 1) && (
                <div className='d-lg-flex justify-content-lg-between align-items-lg-start mt-5 mb-5 mb-lg-2 table-filtering-block'>
                  <div className='d-lg-flex col-lg-11 mb-lg-2'>
                    <div className='col-lg-3 flex-lg-shrink-0 me-2 mb-2'>
                      <label htmlFor='documentTypeSelect'>{t('labels.documentType')}</label>
                      <div>
                        <Autocomplete
                          className='mt-0 w-100'
                          multiple
                          id='documentTypeSelect'
                          openOnFocus={true}
                          options={documentTypeLabelsSorted}
                          disableCloseOnSelect={true}
                          isOptionEqualToValue={(option, value) => option.title === value.title}
                          getOptionLabel={(option) => option.title}
                          value={documentTypeLabel}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>,
                            newValue:
                              | {
                                  title: string;
                                  value: string;
                                }[]
                              | null
                          ) => {
                            setDocumentTypeLabel(newValue);
                          }}
                          renderOption={(props, option, { selected }) => {
                            const { ...optionProps } = props;
                            return (
                              <li key={option.value} {...optionProps}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  checked={selected}
                                />
                                {option.title}
                              </li>
                            );
                          }}
                          renderInput={(params) => <TextField {...params} variant='standard' />}
                        />
                      </div>
                    </div>
                    <div className='col-lg-3 flex-lg-shrink-0 me-2 mb-2'>
                      <label htmlFor='documentWorkflowSelect'>{t('labels.documentWorkflow')}</label>
                      <div>
                        <Autocomplete
                          className='mt-0 w-100'
                          multiple
                          id='documentWorkflowSelect'
                          openOnFocus={true}
                          options={getWorkflowLabels()}
                          disableCloseOnSelect={true}
                          isOptionEqualToValue={(option, value) => option.title === value.title}
                          getOptionLabel={(option) => option.title}
                          value={documentWorkflowLabel}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>,
                            newValue:
                              | {
                                  title: string;
                                  value: string;
                                }[]
                              | null
                          ) => {
                            setDocumentWorkflowLabel(newValue);
                          }}
                          renderOption={(props, option, { selected }) => {
                            const { ...optionProps } = props;
                            return (
                              <li key={option.value} {...optionProps}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  checked={selected}
                                />
                                {option.title}
                              </li>
                            );
                          }}
                          renderInput={(params) => <TextField {...params} variant='standard' />}
                        />
                      </div>
                    </div>
                    <div className='col-lg-3 flex-lg-shrink-0 me-2 mb-2 datepicker-container'>
                      <label id='startDateLabel'>{t('labels.startDate')}</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            aria-labelledby='startDateLabel'
                            value={startDate}
                            maxDate={endDate ? endDate : dayjs(new Date())}
                            format='DD.MM.YYYY'
                            onChange={(newValue) => setStartDate(newValue)}
                            slotProps={{
                              field: { clearable: true },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className='col-lg-3 flex-lg-shrink-0 me-2 mb-lg-2 datepicker-container'>
                      <label id='endDateLabel'>{t('labels.endDate')}</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            format='DD.MM.YYYY'
                            aria-labelledby='endDateLabel'
                            value={endDate}
                            maxDate={dayjs(new Date())}
                            minDate={startDate ? startDate : null}
                            onChange={(newValue) => setEndDate(newValue)}
                            slotProps={{
                              field: { clearable: true },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div
                    className='d-flex mb-lg-2 justify-content-end'
                    style={{ paddingTop: '1.5rem' }}
                  >
                    <Tooltip title={t('buttons.resetFilters')} placement='top' arrow>
                      <span className='d-block'>
                        <IconButton
                          aria-label={t('buttons.resetFilters')}
                          disabled={
                            documentTypeLabel.length === 0 &&
                            documentWorkflowLabel.length === 0 &&
                            !startDate &&
                            !endDate
                          }
                          size='medium'
                          onClick={() => {
                            setDocumentTypeLabel([]);
                            setDocumentWorkflowLabel([]);
                            setProfileNameLabel([]);
                            setStartDate(null);
                            setEndDate(null);
                          }}
                        >
                          <RotateLeftOutlinedIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              )}
              <TableContainer
                elevation={0}
                sx={{ py: 2, px: 3, borderRadius: 5 }}
                variant='outlined'
                component={Paper}
                style={{ marginBottom: '1.5rem' }}
              >
                <Table aria-label='templates table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortingBy === 'name'}
                          direction={sortingBy === 'name' ? sorting : 'asc'}
                          onClick={() => handleSorting('name')}
                        >
                          {t('templatesTable.nameColumnLabel')}
                          {sortingBy === 'name' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {sorting === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortingBy === 'fileType'}
                          direction={sortingBy === 'fileType' ? sorting : 'asc'}
                          onClick={() => handleSorting('fileType')}
                          style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
                        >
                          {t('labels.fileType')}
                          {sortingBy === 'fileType' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {sorting === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortingBy === 'date'}
                          direction={sortingBy === 'date' ? sorting : 'asc'}
                          onClick={() => handleSorting('date')}
                          style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
                        >
                          {t('labels.date')}
                          {sortingBy === 'date' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {sorting === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                        {t('labels.labels')}
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        {t('templatesTable.actionColumnLabel')}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ px: 2 }}>
                    {getItemsArray().map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell
                          component='th'
                          scope='row'
                          onClick={() => handleNavigateByRowClick(row)}
                        >
                          <div className='templates-table-name'>
                            {row.name.endsWith('.pdf') ||
                            row.name.endsWith('.docx') ||
                            row.name.endsWith('.doc')
                              ? row.name
                                  .replace('.pdf', '')
                                  .replace('.docx', '')
                                  .replace('.doc', '')
                              : row.name}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell onClick={() => handleNavigateByRowClick(row)}>
                          {row.fileType === 'bk' ? (
                            <Tooltip title={t('labels.bkGeneratedDocument')} placement='top' arrow>
                              <div className={'file-type-label ' + row.fileType}>
                                <Brand />
                              </div>
                            </Tooltip>
                          ) : (
                            <div className={'file-type-label ' + row.fileType}>{row.fileType}</div>
                          )}
                        </StyledTableCell>
                        <StyledTableCell onClick={() => handleNavigateByRowClick(row)}>
                          <div className='date-time white-space-no-wrap'>
                            {Helpers.timeStampToDateV2(row.updated)}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          {('du_types' in row && row.du_types.length > 0) ||
                          ('du_workflows' in row && row.du_workflows.length > 0) ||
                          ('employees' in row && row.employees.length > 0) ||
                          ('suppliers' in row && row.suppliers.length > 0) ||
                          ('customers' in row && row.customers.length > 0) ||
                          ('dc_types' in row && row.dc_types.length > 0) ||
                          ('dc_workflows' in row && row.dc_workflows.length > 0) ? (
                            <PopoverWithButton
                              id={String(row.id)}
                              buttonText={String(
                                ('du_types' in row ? row.du_types.length : 0) +
                                  ('du_workflows' in row ? row.du_workflows.length : 0) +
                                  ('dc_types' in row ? row.dc_types.length : 0) +
                                  ('dc_workflows' in row ? row.dc_workflows.length : 0) +
                                  ('employees' in row ? row.employees.length : 0) +
                                  ('suppliers' in row ? row.suppliers.length : 0) +
                                  ('customers' in row ? row.customers.length : 0)
                              )}
                            >
                              <Stack
                                direction='row'
                                sx={{
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                                  gap: '0.375rem',
                                  maxWidth: '200px',
                                  padding: '0.5rem',
                                }}
                              >
                                {'du_types' in row
                                  ? row.du_types.map((el) => <Chip key={el} label={el} />)
                                  : null}
                                {'du_workflows' in row
                                  ? row.du_workflows.map((el) => <Chip key={el} label={el} />)
                                  : null}
                                {'dc_types' in row
                                  ? row.dc_types.map((el) => <Chip key={el} label={el} />)
                                  : null}
                                {'dc_workflows' in row
                                  ? row.dc_workflows.map((el) => <Chip key={el} label={el} />)
                                  : null}
                                {row.employees.map((employeeId) => {
                                  const employee = employees.find((emp) => emp.id === employeeId);
                                  return employee ? (
                                    <Chip
                                      key={employeeId}
                                      label={employee.first_name + ' ' + employee.last_name}
                                    />
                                  ) : null;
                                })}

                                {row.suppliers.map((supplierId) => {
                                  const supplier = suppliers.find((sup) => sup.id === supplierId);
                                  return supplier ? (
                                    <Chip key={supplierId} label={supplier.name} />
                                  ) : null;
                                })}
                                {row.customers.map((customerId) => {
                                  const customer = customers.find((cus) => cus.id === customerId);
                                  return customer ? (
                                    <Chip key={customerId} label={customer.customer_name} />
                                  ) : null;
                                })}
                              </Stack>
                            </PopoverWithButton>
                          ) : null}
                        </StyledTableCell>
                        <ActionsCell align='right' row={row} />
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}
        </>
      )}
      {pendingStatus ? <ProgressOverlay /> : ''}
    </div>
  );
};

export default DocumentsCategoryPage;
