import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/user/userSlice';
import { useUser } from '@clerk/clerk-react';

export const useGreetingUser = () => {
  const userInfo = useSelector(selectUser);
  const { t } = useTranslation();
  let firstName = '';

  const { isSignedIn, user, isLoaded } = useUser();

  if (isLoaded && isSignedIn) {
    firstName = user.fullName;
  } else {
    firstName = userInfo?.first_name ? userInfo.first_name : '';
  }

  const now = moment();
  const hours = now.hours();

  const getWelcoming = () => {
    if (hours >= 4 && hours < 12) {
      return t('welcomingText.morning');
    }
    if (hours >= 12 && hours < 18) {
      return t('welcomingText.afternoon');
    }
    if (hours >= 18 && hours < 22) {
      return t('welcomingText.evening');
    }
    if ((hours >= 22 && hours < 24) || (hours >= 0 && hours < 4)) {
      return t('welcomingText.night');
    }
  };

  return {
    greeting: `${getWelcoming()}${`, ${firstName} `}`,
    date: moment().format('dddd, MMMM D'),
  };
};
