import React, { ChangeEvent, useState, useEffect } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import Brand from '../../../static/assets/svg/brand.svg';
import {
  Avatar,
  Chip,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import EmploymentSettingsIcon from '../../../static/assets/svg/menu/profile-icon.svg';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import dayjs, { Dayjs } from 'dayjs';
import { fetchCompanies, selectAllCompanies } from '../../slices/companies/companiesSlice';
import { useParams, useNavigate } from 'react-router-dom';
import QualificationsIcon from '../../../static/assets/svg/menu/qualifications.svg';
import EmergencySettingsIcon from '../../../static/assets/svg/menu/emergency.svg';
import { BLACK_COLOR, LIGHT_GREY } from '../../common/constants/colors';
import { RELATIONSHIP } from '../../common/constants/relationship';
import { POSITION_TYPES } from '../../common/constants/position_types';
import { NWClient } from '../../client/NWClient';
import { toast } from 'react-toastify';
import 'dayjs/locale/en-gb';
import { Company } from '../../models/company.model';
import { Employee } from '../../models/employee.model';
import { fetchEmployees, selectEmployeeById } from '../../slices/employees/employeesSlice';
import { RootState } from '../../store';
import { ROUTES } from '../../common/constants/routes';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import DialogItem from '../../common/dialog-item/DialogItem';
import CloseIcon from '@mui/icons-material/Close';
import PlanUpgradingMessage from '../../common/plan-upgrading-message/PlanUpgradingMessage';
import DescriptionIcon from '@mui/icons-material/Description';
import { Helpers } from '../../common/helpers/helpers';
import PopoverWithButton from '../../common/popover-with-button/PopoverWithButton';

const EmployeeProfilePageForAdmin = () => {
  const { userId } = useParams();
  const company = useAppSelector(selectAllCompanies)[0];
  const employee = useAppSelector((state: RootState) => selectEmployeeById(state, Number(userId)));
  const dispatch = useAppDispatch();
  const [pendingStatus, setPendingStatus] = useState(false);
  const [inviteEmail, setInviteEmail] = useState<string | null>('');
  const [employeeUserId, setEmployeeUserId] = useState<number | null>(null);
  const [email, setEmail] = useState<string | null>('');
  const [firstName, setFirstName] = useState<string | null>('');
  const [middleName, setMiddleName] = useState<string | null>('');
  const [lastName, setLastName] = useState<string | null>('');
  const [address, setAddress] = useState<string | null>('');
  const [phoneNumber, setPhoneNumber] = useState<string | null>('');
  const [positionTitle, setPositionTitle] = useState<string>('');
  const [positionType, setPositionType] = useState<string | null>('');
  const [department, setDepartment] = useState<string | null>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [emailBusiness, setEmailBusiness] = useState<string | null>('');
  const [taxNumber, setTaxNumber] = useState<string | null>('');
  const [employeeId, setEmployeeId] = useState<string | null>('');
  const [positionDescription, setPositionDescription] = useState<string | null>('');
  const [degree, setDegree] = useState<string>('');
  const [universityName, setUniversityName] = useState<string | null>('');
  const [startDateQualifications, setStartDateQualifications] = useState<Dayjs | null>(null);
  const [endDateQualifications, setEndDateQualifications] = useState<Dayjs | null>(null);
  const [firstNameEmergency, setFirstNameEmergency] = useState<string>('');
  const [lastNameEmergency, setLastNameEmergency] = useState<string | null>('');
  const [emailEmergency, setEmailEmergency] = useState<string | null>('');
  const [addressEmergency, setAddressEmergency] = useState<string | null>('');
  const [phoneNumberEmergency, setPhoneNumberEmergency] = useState<string | null>('');
  const [relationship, setRelationship] = useState<string>('');
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [deleteEmployeeMessageVisible, setDeleteEmployeeMessageVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canSubmit = true;
  const [openInvite, setOpenInvite] = useState<boolean>(false);
  const [planUpgradingMessage, setPlanUpgradingMessage] = useState<boolean>(false);
  const [employeeDocuments, setEmployeeDocuments] = useState([]);

  const submitEmployment = (company: Company) => {
    const data = {
      position_title: positionTitle,
      position_description: positionDescription,
      position_type: positionType,
      department: department,
      employee_tax_number: taxNumber,
      employee_id: employee.id,
      employee_identification_number: employeeId,
      start_date: startDate ? startDate.toISOString().substring(0, 10) : null,
      end_date: endDate ? endDate.toISOString().substring(0, 10) : null,
    };

    if (employee.employments.length === 0) {
      NWClient.post(`employee/${employee.id}/employments`, data, true)
        .then(() => {
          submitQualification(company);
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    } else {
      NWClient.put(
        `employee/${employee.id}/employments`,
        Math.max(...employee.employments.map((el: any) => el.id)),
        data,
        true,
        true
      )
        .then(() => {
          submitQualification(company);
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    }
  };
  const submitEmergencyContact = (company: Company) => {
    const data = {
      first_name: firstNameEmergency,
      last_name: lastNameEmergency,
      postal_address: addressEmergency,
      email_address: emailEmergency,
      relationship,
      phone_number: phoneNumberEmergency,
    };
    if (!employee.emergency_contact) {
      NWClient.post(`employee/${employee.id}/emergency_contact`, data, true)
        .then(() => {
          dispatch(fetchCompanies()); //to refactor
          setPendingStatus(false);
          setFieldsErrors(null);
          toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
        })
        .catch((error) => {
          setPendingStatus(false);
          setFieldsErrors(error.response.data);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    } else {
      NWClient.put(
        `employee/${employee.id}/emergency_contact`,
        employee.emergency_contact.id,
        data,
        true,
        true
      )
        .then(() => {
          dispatch(fetchEmployees({ company_id: company.id })); //to refactor
          setPendingStatus(false);
          setFieldsErrors(null);
          toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
        })
        .catch((error) => {
          setPendingStatus(false);
          setFieldsErrors(error.response.data);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    }
  };

  const submitQualification = (company: Company) => {
    const data = {
      degree,
      university_name: universityName,
      start_date: startDateQualifications
        ? startDateQualifications.toISOString().substring(0, 10)
        : null,
      end_date: endDateQualifications ? endDateQualifications.toISOString().substring(0, 10) : null,
    };
    if (employee.qualifications.length === 0) {
      NWClient.post(`employee/${employee.id}/qualifications`, data, true)
        .then(() => {
          submitEmergencyContact(company);
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    } else {
      NWClient.put(
        `employee/${employee.id}/qualifications`,
        employee.qualifications[0].id,
        data,
        true,
        true
      )
        .then(() => {
          submitEmergencyContact(company);
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    }
  };
  const handleMenuClick = () => {
    setOpenInvite(true);
  };
  const handleMenuClose = () => {
    setOpenInvite(false);
  };
  const handleInvite = (email: string) => {
    setLoading(true);
    NWClient.post(
      `user/invite-existing`,
      { email: email, company_id: company?.id },
      false,
      `?fehost=${window.location.host}`
    ).then(() => {
      setLoading(false);
      handleMenuClose();
      toast.success(t('messages.employeeInvited') + email + '.', { theme: 'colored' });
    });
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      email_address: email,
      business_email_address: emailBusiness,
      company_id: company?.id,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      address: address,
      contact_number: phoneNumber,
    };
    if (!userId) {
      NWClient.post('employee', data, true)
        .then((res: Employee) => {
          toast.success(t('messages.employeeCreated'), { theme: 'colored' });
          window.location.href = `${ROUTES.EMPLOYEE_PROFILES}/${res.id}`;
        })
        .catch((error) => {
          setFieldsErrors(error.response.data);
          if (
            error.response?.data?.errors?.length > 0 &&
            (error.response?.data?.errors[0] === t('messages.exceededPlanLimits') ||
              t('messages.exceededPlanLimits').includes(error.response?.data?.errors[0]))
          ) {
            setPlanUpgradingMessage(true);
          } else {
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          }
        })
        .finally(() => {
          setPendingStatus(false);
        });
    } else {
      NWClient.put('employee', Number(userId), data, true)
        .then(() => {
          submitEmployment(company);
        })
        .catch((error) => {
          setFieldsErrors(error.response.data);
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    }
  };

  const handleNavigateByRowClick = (row: any) => {
    navigate(
      `${ROUTES.DOCUMENT_VAULT}/employment/${row.id}${
        row.contract_generation ? '?initialTab=1&contractGenerated=true' : ''
      }`
    );
  };

  const setDetails = () => {
    const userDetails = employee;
    if (employee) {
      setEmployeeUserId(employee.user ? employee.user : null);
      setEmail(employee.email_address ? employee.email_address : '');
      setInviteEmail(employee.business_email_address ? employee.business_email_address : '');
      setAddress(employee.address ? employee.address : '');
      setFirstName(employee.first_name ? employee.first_name : '');
      setMiddleName(employee.middle_name ? employee.middle_name : '');
      setLastName(employee.last_name ? employee.last_name : '');
      setPhoneNumber(employee.contact_number ? employee.contact_number : '');
      setEmailBusiness(employee.business_email_address);
    }
    if (userDetails) {
      if (userDetails.employments.length > 0) {
        const employmentsId = Math.max(...employee.employments.map((el: any) => el.id));
        const employmentInstance = userDetails.employments.find(
          (el: any) => el.id === employmentsId
        );
        setEmployeeId(
          employmentInstance.employee_identification_number
            ? employmentInstance.employee_identification_number
            : ''
        );
        setPositionTitle(employmentInstance.position_title);
        setDepartment(employmentInstance.department);
        setTaxNumber(employmentInstance.employee_tax_number);
        setPositionType(employmentInstance.position_type);
        setPositionDescription(employmentInstance.position_description);
        setStartDate(
          employmentInstance.start_date ? dayjs(new Date(employmentInstance.start_date)) : null
        );
        setEndDate(
          employmentInstance.end_date ? dayjs(new Date(employmentInstance.end_date)) : null
        );
      }
      if (userDetails.qualifications.length > 0) {
        const qualificationsId = Math.max(...employee.qualifications.map((el: any) => el.id));
        const qualificationsInstance = userDetails.qualifications.find(
          (el: any) => el.id === qualificationsId
        );
        setDegree(qualificationsInstance.degree);
        setUniversityName(qualificationsInstance.university_name);
        setStartDateQualifications(
          qualificationsInstance.start_date
            ? dayjs(new Date(qualificationsInstance.start_date))
            : null
        );
        setEndDateQualifications(
          qualificationsInstance.end_date ? dayjs(new Date(qualificationsInstance.end_date)) : null
        );
      }
      if (userDetails.emergency_contact) {
        const emergencyContactInstance = userDetails.emergency_contact;
        setFirstNameEmergency(emergencyContactInstance.first_name);
        setLastNameEmergency(emergencyContactInstance.last_name);
        setEmailEmergency(emergencyContactInstance.email_address);
        setAddressEmergency(emergencyContactInstance.postal_address);
        setRelationship(emergencyContactInstance.relationship);
        setPhoneNumberEmergency(emergencyContactInstance.phone_number);
      }
    }
  };

  const positionTypeArray = POSITION_TYPES.map((el) => t(el));
  const relationshipArray = RELATIONSHIP.map((el) => t(el));

  const fetchEmployeeDocuments = (id: number) => {
    NWClient.list('document-upload')
      .then((res) => {
        const employeeDocuments = res.filter((el: any) => el.employees.includes(id));
        setEmployeeDocuments(employeeDocuments);
      })
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  const deleteEmployee = () => {
    setPendingStatus(true);
    NWClient.delete('employee', userId)
      .then(() => {
        toast.success(t('messages.employeeDeleted'), { theme: 'colored' });
        dispatch(fetchEmployees({ company_id: company?.id }))
          .unwrap()
          .then(() => {
            navigate(ROUTES.EMPLOYEE_PROFILES);
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      })
      .catch(() => {
        setPendingStatus(false);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  useEffect(() => {
    if (!userId) {
      setEditMode(true);
    }
  }, []);

  useEffect(() => {
    if (employee) {
      setDetails();
      fetchEmployeeDocuments(employee.id);
      setLoading(false);
    } else {
      dispatch(fetchEmployees({ company_id: company?.id }))
        .then(() => {
          setDetails();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [company, dispatch, employee]);
  return (
    <>
      {loading ? (
        <div className='text-center'>
          {/*<CircularProgress/>*/}
          <ProgressOverlay className='position-relative' />
        </div>
      ) : (
        <div className='profile-details-wrapper'>
          <Grid>
            <div>
              <ValidatorForm onSubmit={handleSubmit}>
                <div className='basic-info'>
                  <Grid container item className='setting-panel-header' alignItems='center'>
                    {employee?.first_name && employee?.last_name && (
                      <div className='invite-employees-item-avatar d-flex justify-content-center'>
                        <Avatar
                          sx={{
                            width: 80,
                            height: 80,
                            marginRight: '1rem',
                            fontSize: '2rem',
                            backgroundColor: LIGHT_GREY,
                            color: BLACK_COLOR,
                          }}
                        >{`${
                          employee && employee.first_name
                            ? employee.first_name[0]
                            : employee.email_address
                        }${
                          employee && employee.last_name
                            ? employee.last_name[0]
                            : employee.email_address
                        }`}</Avatar>
                      </div>
                    )}
                    {userId ? (
                      <h2>
                        {`${employee && employee.first_name ? employee.first_name + ' ' : ''}${
                          employee && employee.middle_name ? employee.middle_name + ' ' : ''
                        }${employee && employee.last_name ? employee.last_name : ''}`}
                      </h2>
                    ) : (
                      <h1>{t('pages.createEmployee')}</h1>
                    )}
                    {userId && (
                      <>
                        {!editMode && !employeeUserId && (
                          <Button
                            style={{ marginLeft: '24rem' }}
                            variant='outlined'
                            size='large'
                            disabled={loading}
                            onClick={handleMenuClick}
                          >
                            {loading ? <CircularProgress size={24} /> : t('buttons.inviteEmployee')}
                          </Button>
                        )}
                        {openInvite && (
                          <DialogItem
                            isErrorMessage={false}
                            open={openInvite}
                            title={`${t(
                              'dialogTitles.chooseEmail'
                            )} ${firstName} ${middleName} ${lastName} ${t(
                              'dialogTitles.chooseEmail2'
                            )}`}
                            noDefaultActionsRow={true}
                            handleClose={() => handleMenuClose()}
                          >
                            <IconButton
                              style={{ position: 'absolute', top: 8, right: 8 }}
                              onClick={handleMenuClose}
                            >
                              <CloseIcon />
                            </IconButton>
                            <Grid item xs={12} md={6}>
                              <InputLabel htmlFor='emailAddress'>
                                {t('labels.emailAddress')}
                              </InputLabel>
                              <Select
                                onChange={(e: SelectChangeEvent) => {
                                  setInviteEmail(e.target.value);
                                }}
                                id='inviteEmail'
                                name='inviteEmail'
                                value={inviteEmail}
                                className='w-100'
                              >
                                <MenuItem
                                  value={email}
                                  onClick={() => {
                                    setInviteEmail(email);
                                  }}
                                >
                                  {email}
                                </MenuItem>
                                <MenuItem
                                  value={emailBusiness}
                                  onClick={() => {
                                    setInviteEmail(emailBusiness);
                                  }}
                                >
                                  {emailBusiness}
                                </MenuItem>
                              </Select>
                            </Grid>
                            <div className='buttons-row d-flex flex-wrap justify-content-end mt-4'>
                              <Button
                                type='button'
                                role='button'
                                variant='contained'
                                size='medium'
                                style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                                onClick={() => handleInvite(inviteEmail)}
                              >
                                {t('buttons.invite')}
                              </Button>
                            </div>
                          </DialogItem>
                        )}
                        <Button
                          style={{ marginLeft: 'auto' }}
                          variant='outlined'
                          type={editMode ? 'button' : 'submit'}
                          size='large'
                          onClick={(e) => {
                            if (editMode) {
                              dispatch(fetchCompanies());
                              handleSubmit(e);
                              setEditMode(false);
                            } else {
                              setEditMode(true);
                            }
                          }}
                        >
                          {!editMode ? t('buttons.edit') : t('buttons.update')}
                        </Button>
                        {!employeeUserId && (
                          <IconButton
                            aria-label='delete'
                            style={{ marginLeft: '0.5rem' }}
                            onClick={() => setDeleteEmployeeMessageVisible(true)}
                          >
                            <Tooltip title={t('messages.deleteEmployee')} placement='top' arrow>
                              <DeleteOutlineOutlinedIcon
                                fontSize='large'
                                style={{ marginRight: 0 }}
                              />
                            </Tooltip>
                          </IconButton>
                        )}
                      </>
                    )}
                  </Grid>
                  <Divider />
                  <div className='setting-panel-body'>
                    <Grid container className='form-controls' spacing={4}>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.firstName')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFirstName(e.target.value)
                          }
                          name='firstName'
                          value={firstName}
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.middleName')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setMiddleName(e.target.value)
                          }
                          name='middleName'
                          value={middleName}
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.lastName')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setLastName(e.target.value)
                          }
                          name='lastName'
                          value={lastName}
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.email')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                          name='email'
                          value={email}
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.businessEmailAddress')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setEmailBusiness(e.target.value)
                          }
                          name='emailBusiness'
                          value={emailBusiness}
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.phoneNumber')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setPhoneNumber(e.target.value)
                          }
                          name='phoneNumber'
                          disabled={!editMode}
                          value={phoneNumber}
                        />
                        {Boolean(fieldsErrors?.contact_number) && (
                          <FormHelperText error={true}>
                            {fieldsErrors?.contact_number.join('.')}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.postalAddress')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setAddress(e.target.value);
                          }}
                          disabled={!editMode}
                          name='address'
                          value={address}
                        />
                        {Boolean(fieldsErrors?.address) && (
                          <FormHelperText error={true}>
                            {fieldsErrors?.address.join('.')}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {userId && (
                  <>
                    <Grid container item className='setting-panel-header' alignItems='center'>
                      <EmploymentSettingsIcon />
                      <h3>{t('settings.titles.employment')}</h3>
                    </Grid>
                    <Divider />
                    <div className='setting-panel-body'>
                      <Grid container className='form-controls' spacing={4}>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.employeeId')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setEmployeeId(e.target.value)
                            }
                            name='employeeId'
                            value={employeeId}
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.employeeTaxNumber')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setTaxNumber(e.target.value)
                            }
                            name='employeeTaxNumber'
                            value={taxNumber}
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.positionTitle')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setPositionTitle(e.target.value)
                            }
                            name='positionTitle'
                            value={positionTitle}
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.department')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setDepartment(e.target.value)
                            }
                            name='department'
                            value={department}
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            select
                            label={t('labels.positionType')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setPositionType(e.target.value)
                            }
                            disabled={!editMode}
                            name='positionType'
                            value={positionType}
                          >
                            {positionTypeArray.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextValidator>
                        </Grid>
                        <Grid item xs={12} md={6} className='datepicker-container'>
                          <InputLabel id='startDateLabel'>{t('labels.startDate')}</InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                            <DemoContainer components={['DatePicker']}>
                              <DatePicker
                                disabled={!editMode}
                                aria-labelledby='startDateLabel'
                                value={startDate}
                                format='DD.MM.YYYY'
                                onChange={(newValue) => setStartDate(newValue)}
                                slotProps={{
                                  field: { clearable: true },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6} className='datepicker-container'>
                          <InputLabel id='endDateLabel'>{t('labels.endDate')}</InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                            <DemoContainer components={['DatePicker']}>
                              <DatePicker
                                disabled={!editMode}
                                format='DD.MM.YYYY'
                                aria-labelledby='endDateLabel'
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                slotProps={{
                                  field: { clearable: true },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel htmlFor='positionDescriptionLabel'>
                            {t('labels.positionDescription')}
                          </InputLabel>
                          <textarea
                            id='positionDescription'
                            name='positionDescription'
                            disabled={!editMode}
                            value={positionDescription}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                              setPositionDescription(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <Grid container item className='setting-panel-header' alignItems='center'>
                      <QualificationsIcon />
                      <h3>{t('settings.titles.qualifications')}</h3>
                    </Grid>
                    <Divider />
                    <div className='setting-panel-body'>
                      <Grid container className='form-controls' spacing={4}>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.degreeOrCertificate')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setDegree(e.target.value)
                            }
                            disabled={!editMode}
                            name='degree'
                            value={degree}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.institutionName')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setUniversityName(e.target.value)
                            }
                            disabled={!editMode}
                            name='universityName'
                            value={universityName}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} className='datepicker-container'>
                          <InputLabel id='startDateLabel'>{t('labels.startDate')}</InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                            <DemoContainer components={['DatePicker']}>
                              <DatePicker
                                disabled={!editMode}
                                aria-labelledby='startDateLabel'
                                value={startDateQualifications}
                                format='DD.MM.YYYY'
                                onChange={(newValue) => setStartDateQualifications(newValue)}
                                slotProps={{
                                  field: { clearable: true },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6} className='datepicker-container'>
                          <InputLabel id='endDateLabel'>{t('labels.endDate')}</InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                            <DemoContainer components={['DatePicker']}>
                              <DatePicker
                                disabled={!editMode}
                                aria-labelledby='endDateLabel'
                                format='DD.MM.YYYY'
                                value={endDateQualifications}
                                onChange={(newValue) => setEndDateQualifications(newValue)}
                                slotProps={{
                                  field: { clearable: true },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </div>
                    <Grid container item className='setting-panel-header' alignItems='center'>
                      <EmergencySettingsIcon />
                      <h3>{t('settings.titles.emergencyContact')}</h3>
                    </Grid>
                    <Divider />
                    <div className='setting-panel-body'>
                      <Grid container className='form-controls' spacing={4}>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.firstName')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFirstNameEmergency(e.target.value)
                            }
                            name='firstNameEmergency'
                            disabled={!editMode}
                            value={firstNameEmergency}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.lastName')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setLastNameEmergency(e.target.value)
                            }
                            name='lastNameEmergency'
                            disabled={!editMode}
                            value={lastNameEmergency}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.email')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setEmailEmergency(e.target.value)
                            }
                            name='emailEmergency'
                            disabled={!editMode}
                            value={emailEmergency}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.phoneNumber')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setPhoneNumberEmergency(e.target.value)
                            }
                            disabled={!editMode}
                            name='phoneNumberEmergency'
                            value={phoneNumberEmergency}
                          />
                          {Boolean(fieldsErrors?.phone_number) && (
                            <FormHelperText error={true}>
                              {fieldsErrors?.phone_number.join('.')}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            label={t('labels.postalAddress')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setAddressEmergency(e.target.value);
                            }}
                            disabled={!editMode}
                            name='addressEmergency'
                            value={addressEmergency}
                          />
                          {Boolean(fieldsErrors?.postal_address) && (
                            <FormHelperText error={true}>
                              {fieldsErrors?.postal_address.join('.')}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextValidator
                            select
                            label={t('labels.relationship')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setRelationship(e.target.value)
                            }
                            disabled={!editMode}
                            name='relationship'
                            value={relationship}
                          >
                            {relationshipArray.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextValidator>
                        </Grid>
                      </Grid>
                    </div>
                    <Grid
                      container
                      item
                      className='setting-panel-header'
                      alignItems='center'
                      style={{ marginBottom: '2rem' }}
                    >
                      <DescriptionIcon />
                      <h3>{t('settings.titles.documents')}</h3>
                    </Grid>
                    <Grid container>
                      <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('labels.documents')}</TableCell>
                              <TableCell align='left'>{t('labels.fileType')}</TableCell>
                              <TableCell align='center'>{t('labels.date')}</TableCell>
                              <TableCell align='center'>{t('labels.labels')}</TableCell>
                            </TableRow>
                          </TableHead>
                          {employeeDocuments && employeeDocuments.length > 0 ? (
                            employeeDocuments.map((employeeDocument: any) => (
                              <TableRow
                                key={employeeDocument.name}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                  '&:hover': { cursor: 'pointer', color: '#6414DB' },
                                }}
                                onClick={() => handleNavigateByRowClick(employeeDocument)}
                              >
                                <TableCell component='th' scope='row'>
                                  {employeeDocument.name}
                                </TableCell>
                                <TableCell align='center'>
                                  {employeeDocument.du_filetype === 'bk' ? (
                                    <Tooltip
                                      title={t('labels.bkGeneratedDocument')}
                                      placement='top'
                                      arrow
                                    >
                                      <div
                                        className={
                                          'file-type-label ' + employeeDocument.du_filetype
                                        }
                                      >
                                        <Brand />
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <div
                                      className={'file-type-label ' + employeeDocument.du_filetype}
                                    >
                                      {employeeDocument.du_filetype}
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell align='center'>
                                  {Helpers.timeStampToDateV2(employeeDocument.updated)}
                                </TableCell>
                                <TableCell align='center'>
                                  {('du_types' in employeeDocument &&
                                    employeeDocument.du_types.length > 0) ||
                                  ('du_workflows' in employeeDocument &&
                                    employeeDocument.du_workflows.length > 0) ||
                                  ('customers' in employeeDocument &&
                                    employeeDocument.employees.length > 0) ||
                                  ('dc_types' in employeeDocument &&
                                    employeeDocument.dc_types.length > 0) ||
                                  ('dc_workflows' in employeeDocument &&
                                    employeeDocument.dc_workflows.length > 0) ? (
                                    <PopoverWithButton
                                      id={String(employeeDocument.id)}
                                      buttonText={String(
                                        ('du_types' in employeeDocument
                                          ? employeeDocument.du_types.length
                                          : 0) +
                                          ('du_workflows' in employeeDocument
                                            ? employeeDocument.du_workflows.length
                                            : 0) +
                                          ('dc_types' in employeeDocument
                                            ? employeeDocument.dc_types.length
                                            : 0) +
                                          ('dc_workflows' in employeeDocument
                                            ? employeeDocument.dc_workflows.length
                                            : 0) +
                                          ('customers' in employeeDocument
                                            ? employeeDocument.employees.length
                                            : 0)
                                      )}
                                    >
                                      <Stack
                                        direction='row'
                                        sx={{
                                          alignItems: 'center',
                                          flexWrap: 'wrap',
                                          gap: '0.375rem',
                                          maxWidth: '200px',
                                          padding: '0.5rem',
                                        }}
                                      >
                                        {'du_types' in employeeDocument
                                          ? employeeDocument.du_types.map((el: any) => (
                                              <Chip key={el} label={el} />
                                            ))
                                          : null}
                                        {'du_workflows' in employeeDocument
                                          ? employeeDocument.du_workflows.map((el: any) => (
                                              <Chip key={el} label={el} />
                                            ))
                                          : null}
                                        {'dc_types' in employeeDocument
                                          ? employeeDocument.dc_types.map((el: any) => (
                                              <Chip key={el} label={el} />
                                            ))
                                          : null}
                                        {'dc_workflows' in employeeDocument
                                          ? employeeDocument.dc_workflows.map((el: any) => (
                                              <Chip key={el} label={el} />
                                            ))
                                          : null}
                                        <Chip key='id' label={employee.first_name}></Chip>
                                      </Stack>
                                    </PopoverWithButton>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <div className='text-center'>{t('messages.noDocuments')}</div>
                          )}
                        </Table>
                      </TableContainer>
                    </Grid>
                  </>
                )}
                {editMode && (
                  <Grid container className='buttons-row pb-5' justifyContent='flex-end'>
                    <Button
                      type='submit'
                      role='button'
                      disabled={!canSubmit}
                      variant='contained'
                      size='large'
                    >
                      {!userId ? t('buttons.save') : t('buttons.update')}
                    </Button>
                  </Grid>
                )}
                {pendingStatus ? <ProgressOverlay /> : ''}
              </ValidatorForm>
            </div>
          </Grid>
        </div>
      )}
      <DialogItem
        isErrorMessage={false}
        open={deleteEmployeeMessageVisible}
        title={
          deleteEmployeeMessageVisible
            ? t('dialogTitles.deleteEmployee')
            : t('dialogTitles.deleteDefault')
        }
        text={
          deleteEmployeeMessageVisible
            ? t('messages.deleteEmployeeDialog')
            : t('messages.deleteDefault')
        }
        noDefaultActionsRow={true}
        handleClose={() => {
          setDeleteEmployeeMessageVisible(false);
        }}
      >
        <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
          <Button
            type='button'
            role='button'
            variant='outlined'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => deleteEmployee()}
          >
            {t('buttons.delete')}
          </Button>
          <Button
            type='button'
            role='button'
            variant='contained'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => {
              setDeleteEmployeeMessageVisible(false);
            }}
          >
            {t('buttons.goBack')}
          </Button>
        </div>
      </DialogItem>
      {planUpgradingMessage && (
        <PlanUpgradingMessage
          open={planUpgradingMessage}
          text={t('messages.monthLimitReached')}
          handleClose={() => setPlanUpgradingMessage(false)}
        />
      )}
    </>
  );
};

export default EmployeeProfilePageForAdmin;
