import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';
import { BLACK_COLOR, LIGHT_GREY } from '../../common/constants/colors';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { CompanyMember } from '../../models/member.model';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { fetchEmployees, selectAllEmployees } from '../../slices/employees/employeesSlice';
import { EmployeesCreateModal } from '../company-create/components/modal/EmployeesCreateModal';
import Button from '@mui/material/Button';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { Employee } from '../../models/employee.model';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import DialogItem from '../../common/dialog-item/DialogItem';
import { fetchCompanies } from '../../slices/companies/companiesSlice';

const EmployeeProfiles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pendingStatus, setPendingStatus] = useState(true);
  const [isOpenEmployeesCreation, setIsOpenEmployeesCreation] = useState(false);
  const [planMessageVisible, setPlanMessageVisible] = useState(false);
  const [employeesLimitReached, setEmployeesLimitReached] = useState(false);
  const handleClose = () => {
    setIsOpenEmployeesCreation(false);
  };
  const company = useAppSelector(selectAllCompanies)[0];
  const employeesCreatedByAdmin = useAppSelector(selectAllEmployees);
  const employees: CompanyMember[] = company?.members || [];
  const employeesFiltered = employees.filter((el) =>
    el.groups.every((item) => item.name !== 'Company admin')
  );
  const employeesSorted = employeesFiltered.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );

  const handleCreateEmployee = () => {
    if (!company.stripe_product_id && !company.plan_settings.stripe_product_id) {
      if (
        typeof company.plan_settings.free === 'object' &&
        company.plan_settings.free.employee_profile === 0
      ) {
        setPlanMessageVisible(true);
      } else if (
        typeof company.plan_settings.free === 'object' &&
        employeesCreatedByAdmin?.length === company.plan_settings.free.employee_profile
      ) {
        setPlanMessageVisible(true);
        setEmployeesLimitReached(true);
      } else if (
        typeof company.plan_settings.free === 'object' &&
        employeesCreatedByAdmin?.length < company.plan_settings.free.employee_profile
      ) {
        navigate(ROUTES.EMPLOYEE_PROFILE_CREATE);
      }
    } else if (company.stripe_product_id && !company.plan_settings.stripe_product_id) {
      if (
        typeof company.plan_settings.legacy === 'object' &&
        employeesCreatedByAdmin?.length === company.plan_settings.legacy.employee_profile
      ) {
        setPlanMessageVisible(true);
        setEmployeesLimitReached(true);
      } else if (
        typeof company.plan_settings.legacy === 'object' &&
        employeesCreatedByAdmin?.length < company.plan_settings.legacy.employee_profile
      ) {
        navigate(ROUTES.EMPLOYEE_PROFILE_CREATE);
      }
    } else if (!company.stripe_product_id && company.plan_settings.stripe_product_id) {
      if (
        typeof company.plan_settings.stripe_product_id === 'string' &&
        typeof company.plan_settings[company.plan_settings.stripe_product_id] === 'object' &&
        employeesCreatedByAdmin?.length === // @ts-expect-error: can be error
          company.plan_settings[company.plan_settings.stripe_product_id].employee_profile
      ) {
        setPlanMessageVisible(true);
        setEmployeesLimitReached(true);
      } else {
        if (
          typeof company.plan_settings.stripe_product_id === 'string' &&
          typeof company.plan_settings[company.plan_settings.stripe_product_id] === 'object' &&
          employeesCreatedByAdmin?.length < // @ts-expect-error: can be error
            company.plan_settings[company.plan_settings.stripe_product_id].employee_profile
        ) {
          navigate(ROUTES.EMPLOYEE_PROFILE_CREATE);
        }
      }
    }
  };

  useEffect(() => {
    if (company) {
      dispatch(fetchEmployees({ company_id: company?.id }));
      setPendingStatus(false);
    } else {
      dispatch(fetchCompanies())
        .unwrap()
        .finally(() => setPendingStatus(false));
    }
  }, [company]);

  return (
    <React.Fragment>
      <div className='header-wrapper employees-page-header d-md-flex d-lg-flex justify-content-md-between justify-content-lg-between align-items-md-start align-items-lg-start mb-3'>
        <h1 className='mb-2 fs-2 mb-md-0 mb-lg-0'>{t('employeeProfiles.header')}</h1>
        <div className='controls-wrapper text-center text-md-left text-lg-left'>
          <Button
            type='button'
            variant='outlined'
            size='large'
            onClick={() => handleCreateEmployee()}
          >
            <PersonAddAltOutlinedIcon sx={{ width: 30, height: 30 }} color='primary' />
            <span>{t('buttons.addAnEmployee')}</span>
          </Button>
        </div>
      </div>
      <div className='invite-employees-items employees-list d-flex align-items-start justify-content-center justify-content-md-start flex-wrap'>
        {employeesCreatedByAdmin.length > 0 ? (
          employeesCreatedByAdmin.map((employee: Employee) => (
            <div
              role='button'
              tabIndex={-1}
              key={employee.email_address}
              className='invite-employees-item'
              onClick={() => navigate(ROUTES.EMPLOYEE_PROFILES + '/' + employee.id)}
              onKeyUp={(e) => console.log(e)}
            >
              <div className='invite-employees-item-avatar d-flex justify-content-center'>
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    fontSize: '2rem',
                    backgroundColor: LIGHT_GREY,
                    color: BLACK_COLOR,
                  }}
                >
                  {employee.first_name && employee.last_name
                    ? `${employee?.first_name?.[0]}${employee.last_name?.[0]}`
                    : ''}
                </Avatar>
              </div>
              {employee.first_name && employee.last_name && (
                <div className='invite-employees-item-name'>
                  {employee.first_name} {employee.last_name}
                </div>
              )}
              <div className='invite-employees-item-contacts'>
                {employee.email || employee.email_address}
              </div>
            </div>
          ))
        ) : (
          <div className='no-employees-message text-center mt-5 ps-5 pe-5'>
            <p className='mb-5 fs-5'>{t('messages.noEmployeesLine1')}</p>
            <p className='fs-5 fst-italic'>{t('messages.noEmployeesLine2')}</p>
          </div>
        )}
      </div>
      <EmployeesCreateModal open={isOpenEmployeesCreation} onClose={handleClose} />
      {pendingStatus ? <ProgressOverlay /> : ''}
      {planMessageVisible && (
        <DialogItem
          isErrorMessage={false}
          open={planMessageVisible}
          title={null}
          text={
            employeesLimitReached ? t('messages.limitEmployees') : t('messages.paidPlanEmployees')
          }
          noDefaultActionsRow={true}
          handleClose={() => {
            setPlanMessageVisible(false);
            setEmployeesLimitReached(false);
          }}
        >
          <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
            <Button
              onClick={() => {
                setPlanMessageVisible(false);
                setEmployeesLimitReached(false);
              }}
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              type='button'
              role='button'
              variant='outlined'
              size='medium'
            >
              {t('buttons.later')}
            </Button>
            <Button
              type='button'
              role='button'
              variant='contained'
              size='medium'
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              onClick={() => {
                navigate(ROUTES.PRICING);
              }}
            >
              {t('buttons.ok')}
            </Button>
          </div>
        </DialogItem>
      )}
    </React.Fragment>
  );
};

export default EmployeeProfiles;
