import { useSelector } from 'react-redux';
import { fetchUser, selectUser, selectUserFetchStatus } from '../../../slices/user/userSlice';
import { useEffect } from 'react';
import { ROUTES } from '../../../common/constants/routes';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';

export const useUserInfo = async () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isSignedIn, isLoaded } = useAuth();

  const currentUser = useSelector(selectUser);
  const fetchUserStatus = useSelector(selectUserFetchStatus);

  const locationExceptions = [
    ROUTES.SIGN_UP,
    ROUTES.EMAIL_SENT,
    ROUTES.PROCESS_SUCCESS,
    ROUTES.CONFIRMATION,
    ROUTES.LOGIN,
    ROUTES.ACCEPTED_INVITE,
    ROUTES.JOIN_COMPANY,
    ROUTES.PASSWORD_RESET,
    ROUTES.DEMO_LOGIN,
    ROUTES.DEMO_SIGNUP,
  ];

  const locationIncludeExceptionUrl = locationExceptions.some((el) => {
    return location.pathname.includes(el);
  });

  useEffect(() => {
    if (!isLoaded) return;
    if (isSignedIn && !locationIncludeExceptionUrl) {
      dispatch(fetchUser());
    } else if (!isSignedIn && !locationIncludeExceptionUrl) {
      navigate(ROUTES.LOGIN);
    }
  }, [isSignedIn, isLoaded]);

  return {
    currentUser,
    userLoading: fetchUserStatus === 'loading',
  };
};
