import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, IconButton } from '@mui/material';
import { InviteEmployeePanel } from '../InviteEmployeePanel';
import { Company } from '../../../../models/company.model';
import {
  fetchCategories,
  selectAllCategories,
} from '../../../../slices/categories/categoriesSlice';
import { NWClient } from '../../../../client/NWClient';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { User } from '../../../../models/user.model';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from '../../../../common/constants/routes';
import { RolesGate } from '../../../../common/roles-gate';
import { GROUPS } from '../../../../common/constants/groups';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useTranslation } from 'react-i18next';
import { DashboardLinks } from '../DashboardLinks';
import SVG from 'react-inlinesvg';
import Button from '@mui/material/Button';
import DialogItem from '../../../../common/dialog-item/DialogItem';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { fetchCompanies, selectAllCompanies } from '../../../../slices/companies/companiesSlice';
import { useAuth } from '@clerk/clerk-react';

interface AdminDashboardPanelProp {
  currentUser: User;
}

export const AdminDashboardPanel = ({ currentUser }: AdminDashboardPanelProp) => {
  const { t } = useTranslation();
  const company = useAppSelector(selectAllCompanies)[0];
  const [loading, setLoading] = useState(false);
  const [aiSummaryDialogVisible, setAISummaryDialogVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isSignedIn, isLoaded } = useAuth();
  useEffect(() => {
    if (!isLoaded) return;
    if (!isSignedIn) {
      navigate(ROUTES.LOGIN);
    }
  }, [isSignedIn, isLoaded, navigate]);

  return loading ? (
    <div className='text-center'>
      {/*<CircularProgress size={60}/>*/}
      <ProgressOverlay className='position-relative' />
    </div>
  ) : (
    <>
      {company && (
        <Grid container className='invite-employees-panel'>
          <Grid container item alignItems='center' justifyContent='center'>
            <IconButton
              style={{ borderRadius: 30, padding: 20 }}
              onClick={() => setAISummaryDialogVisible(true)}
            >
              <SVG
                src={'assets/svg/ai-icon.svg'}
                style={{ width: 30, height: 30, stroke: '#6414DB', fill: '#6414DB' }}
              />
              <span style={{ marginLeft: 10 }}>{t('buttons.getSummaryWithAI')}</span>
            </IconButton>
          </Grid>
        </Grid>
      )}
      <DashboardLinks company={company} />
      <Grid container justifyContent='center'>
        <RolesGate group={GROUPS.COMPANY_ADMIN}>
          {!company && (
            <Grid container className='invite-employees-panel'>
              <Grid container item alignItems='center' justifyContent='center'>
                <IconButton
                  style={{ borderRadius: 30, padding: 20 }}
                  onClick={() => navigate(ROUTES.COMPANY_CREATE)}
                >
                  <AddBusinessIcon sx={{ width: 30, height: 30 }} color='primary' />
                  <span style={{ marginLeft: 10 }}>{t('buttons.createCompany')}</span>
                </IconButton>
              </Grid>
            </Grid>
          )}
        </RolesGate>
      </Grid>
      <DialogItem
        isErrorMessage={false}
        open={aiSummaryDialogVisible}
        title={t('dialogTitles.getSummaryWithAI')}
        text={t('messages.aiSummaryMessage')}
        noDefaultActionsRow={true}
        handleClose={() => {
          setAISummaryDialogVisible(false);
        }}
      >
        <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
          <Button
            type='button'
            role='button'
            variant='outlined'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => {
              setAISummaryDialogVisible(false);
            }}
          >
            {t('buttons.goBack')}
          </Button>
          <Button
            onClick={() => navigate(ROUTES.DOCUMENT_VAULT)}
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            type='button'
            role='button'
            variant='contained'
            size='medium'
          >
            {t('buttons.openVault')}
          </Button>
        </div>
      </DialogItem>
    </>
  );
};
